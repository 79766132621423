import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueClipboard from "vue-clipboard2";
import ElementUi from 'element-ui'
import './assets/gobal.css'
import './assets/cssFolder/styles.css'
import 'element-ui/lib/theme-chalk/index.css';
import {message} from "@/utils/resemessage";
import hevueImgPreviewf from "hevue-img-preview";
import * as echarts from 'echarts'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import '@/assets/font_3973656_hizodlue3b/iconfont.css'
import { createPinia,PiniaVuePlugin } from 'pinia'
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.config.productionTip = false
Vue.use(PiniaVuePlugin)
Vue.use(hevueImgPreviewf)
// Vue.use(ScreenShort)
Vue.echarts = echarts
Vue.use(ElementUi)
Vue.prototype.$message = message
Vue.use(VueClipboard)
const pinia = createPinia()
new Vue({
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')
