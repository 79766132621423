<script>
import {
    AddressIndex, addShopAddress, editShopAddress,
    getAddressDetail,
    getAddressList,
    getProvince, getRegionData
} from "@/api/department";

export default {
    name: 'addressVue',
    data() {
        return {
            addressData: [],
            addressTotal: 0,
            addressPage: 1,
            addressLimit: 10,
            dialogAddress: false,
            AddressDate: {
                reciever_name: '',
                phone: '',
                receiver_detail: '',
                street_id: false,
                receiver_provinc: '',
                province_code: '',
                city_code: '',
                receiver_city: '',
                receiver_district: '',
                district_code: '',
            },
            props: {
                value: 'code',
                label: 'name',
                muliple: true,
                emitPath: false,
                lazy: true,
                lazyLoad: (node, resolve) => {
                    const {level, value} = node;
                    this.lazyLoadNext(level, value, resolve)

                }
            },
            provicen: [],
            provicenMark: [],
            DistinguishMark: [],
            addressTwo: [],
            addressThree: [],
            AddressVisible: true,
            loading: true,
            getVisible:false,
            form: {
                reciever_name: [
                    {required: true, message: '请输入寄件人姓名', trigger: 'blur'},
                ],
                phone: [
                    {required: true, message: '请输入寄件人姓名', trigger: 'blur'},
                    {min: 11, max: 11, message: '长度为11位的数字', trigger: 'blur'}
                ],
                receiver_provinc: [
                    {required: true, message: '请选择省市区', trigger: 'change'},
                ],
                receiver_detail: [
                    {required: true, message: '请输入详细地址', trigger: 'blur'},
                ]
            },
            getLoading: true
        }
    },
    watch: {},
    mounted() {
        this.getDate()

    },
    methods: {
        reWrite(val) {
            this.AddressVisible = true
            const data = {address_id: val}
            getAddressDetail(data).then(res => {
                if (res.code === 1) {
                    this.AddressDate = res.data
                } else {
                    this.$message.warning(res.msg)
                }
            })
            this.dialogAddress = true
            if (this.dialogAddress) {
                getProvince().then(res => {
                    if (res.code === 1) {
                        this.provicen = res.data
                        this.$nextTick(() => {
                            if (this.provicen.length !== 0) {

                                this.getMarket(this.AddressDate.province_code, false)
                                if (this.provicenMark.length !== 0) {
                                    setTimeout(()=>{
                                        this.getDistinguish(this.AddressDate.city_code, false)
                                    })
                                }

                            }
                        })
                    }
                    else
                        {
                            this.$message.warning(res.msg)
                        }
                    }
                )
                }
            }
        ,
            async postAddress(val)
            {
                console.log(this.AddressDate)
                //手机号验证
                if (!/^1[3456789]\d{9}$/.test(this.AddressDate.phone)) {

                    this.$message.error('请输入正确的手机号')
                }else if(this.AddressDate.province_code===""||this.AddressDate.city_code===""||this.AddressDate.district_code===""){
                    this.getVisible=true
                }else {
                    console.log(1111111)
                        if (val) {
                            const data = {
                                reciever_name: this.AddressDate.reciever_name,
                                phone: this.AddressDate.phone,
                                receiver_provinc: this.AddressDate.receiver_provinc,
                                province_code: this.AddressDate.province_code,
                                receiver_city: this.AddressDate.receiver_city,
                                city_code: this.AddressDate.city_code,
                                receiver_district: this.AddressDate.receiver_district,
                                district_code: this.AddressDate.district_code,
                                is_default: this.AddressDate.is_default ? 1 : 0,
                                receiver_detail: this.AddressDate.receiver_detail,
                                address_id: val
                            }
                            await editShopAddress(data).then(res => {
                                this.dialogAddress = false
                                if (res.code == 1) {
                                    this.getDate()
                                    this.$message.success(res.msg)
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        } else {
                            const data = {
                                reciever_name: this.AddressDate.reciever_name,
                                phone: this.AddressDate.phone,
                                receiver_provinc: this.AddressDate.receiver_provinc,
                                province_code: this.AddressDate.province_code,
                                receiver_city: this.AddressDate.receiver_city,
                                city_code: this.AddressDate.city_code,
                                receiver_district: this.AddressDate.receiver_district,
                                district_code: this.AddressDate.district_code,
                                is_default: this.AddressDate.is_default ? 1 : 0,
                                receiver_detail: this.AddressDate.receiver_detail,
                            }
                            await addShopAddress(data).then(res => {
                                this.dialogAddress = false
                                if (res.code == 1) {
                                    this.getDate()
                                    this.$message.success(res.msg)
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }

            }
        ,
            addAddress()
            {
                this.AddressDate = { reciever_name: '',
                    phone: '',
                    receiver_detail: '',
                    street_id: false,
                    receiver_provinc: '',
                    province_code: '',
                    city_code: '',
                    receiver_city: '',
                    receiver_district: '',
                    district_code: '',}
                this.AddressVisible = false
                this.dialogAddress = true
                if (this.dialogAddress) {
                    getProvince().then(res => {
                        this.provicen = res.data
                    })
                }
            }
        ,
            getDate()
            {
                this.getLoading = true
                const data = {page: this.addressPage, limit: this.addressLimit}
                AddressIndex(data).then(res => {
                    if (res.code == 1) {
                        this.getLoading = false
                        this.addressData = res.data.data.map(item => {
                            item.idDefault = (item.is_default == 1) || (item.is_send_default == 1) ? '默认' : '设为默认'
                            item.createtime = new Date(item.create_time * 1000).toLocaleString()
                            item.headerSEX = item.reciever_name.slice(0, 1)
                            return item
                        })
                        this.addressTotal = res.data.total
                    } else {
                        this.$notify({
                            title: res.msg,
                            type: 'error'
                        });
                        this.getLoading = false
                    }

                })

            }
        ,
            handleSizeChange(val)
            {
                this.addressLimit = val
                this.getDate()
            }
        ,
            handleCurrentChange(val)
            {
                this.addressPage = val
                this.getDate()
            }
        ,
            synchronization()
            {
                getAddressList().then(res => {
                    this.$message.success(res.msg);
                })
            }
        ,

            getMarket(val, getStatus)
            {
                console.log(val, getStatus)
                if (getStatus) {
                    this.AddressDate.province_code = val
                    this.AddressDate.receiver_provinc = this.provicen.find(el => el.code === val).name
                }
                getRegionData({code: val}).then(res => {
                    console.log(res)
                    if (res.code === 1) {
                        this.provicenMark = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            }
        ,
            getDistinguish(val, getStatus)
            {
                if (getStatus) {
                    this.AddressDate.city_code = val
                    this.AddressDate.receiver_city = this.provicenMark.find(el => el.code === val).name
                }
                getRegionData({code: val}).then(res => {
                    if (res.code === 1) {
                        this.DistinguishMark = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            }
        ,
            getDistrick(val)
            {
                this.AddressDate.district_code = val
                this.AddressDate.receiver_district = this.DistinguishMark.find(el => el.code === val).name
            }
        }
    }
</script>
<template>
    <div>
        <div class="  pb-3  ">
            <div>
                <el-button type="primary" @click="addAddress">新增地址</el-button>
                <el-button @click="synchronization">同步数据</el-button>
            </div>
            <div class="flex flex-col items-center m-2  rounded my-20" v-if="addressData.length === 0">
                <el-empty description="还未创建过地址"></el-empty>
            </div>
            <div class="pt-3 my-8 bg-white border rounded-md " v-loading="getLoading" v-if="addressData.length!==0">
                <el-row :gutter="16" class="minwGet">
                    <el-col :xs="24" :sm="18" :md="12" :lg="8" :xl="6" v-for="item in addressData"
                            :key="item.address_id">
                        <el-card class=" box-w mx-3 my-1 " shadow="hover"
                        >
                            <div slot="header" class=" clearfix ">
                                <div class="bg-wigetjt rounded-full">
                                    <span class="border-red-400 base font-semibold">{{ item.headerSEX }}</span>
                                </div>
                                <div class="marginget">
                                    <button v-if="item.idDefault==='默认'" class="defaultSty"
                                            :disabled="item.idDefault=='默认'?true:false"
                                            style="float: right; "
                                            :type="item.idDefault=='默认'?'primary':'text'">{{ item.idDefault }}
                                    </button>
                                    <el-button @click="reWrite(item.address_id)"
                                               style="float: right; "
                                               type="text">修改
                                    </el-button>
                                </div>
                            </div>
                            <div class="  leading-7">
                                <div><span class="text-gray-400 ">收/发货人：<span
                                    class="text-gray-900">{{ item.reciever_name }}</span></span></div>
                                <div><span class="text-gray-400 ">所在地区：
                           <span class="text-gray-900">{{ item.receiver_provinc }}&nbsp;&nbsp;
                                {{ item.receiver_city }}&nbsp;&nbsp;
                                {{ item.receiver_district }}</span> </span></div>
                                <div><span class="text-gray-400 ">地址：<span
                                    class="text-gray-900">{{ item.receiver_detail }}</span></span></div>
                                <div><span class="text-gray-400 ">手机号：<span
                                    class="text-gray-900">{{ item.phone }}</span></span></div>
                                <div><span class="text-gray-400 ">创建时间：<span
                                    class="text-gray-900">{{ item.createtime }}</span></span></div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div class="flex flex-col items-center m-2" v-if="addressData.length!==0">
                    <el-pagination
                        v-if="addressData.length!==0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="addressPage"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="100"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="addressTotal">
                    </el-pagination>
                </div>
            </div>
            <el-dialog
                v-loading="loading"
                title="新增寄件人姓名"
                :visible.sync="dialogAddress"
                width="40%">
                <el-form :rules="form" ref="form" :model="AddressDate" label-position="right" label-width="100px">
                    <el-form-item label="寄件人姓名" prop="reciever_name">
                        <el-input v-model="AddressDate.reciever_name"></el-input>
                    </el-form-item>
                    <el-form-item label="寄件人手机" prop="phone">
                        <el-input v-model="AddressDate.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="地址">
                        <el-select @change="(val)=>getMarket(val,true)" clearable v-model="AddressDate.province_code"
                                   placeholder="--选择省份--">
                            <el-option
                                v-for="item in provicen"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                            </el-option>
                        </el-select>
                        &nbsp;
                        <el-select @change="(val)=>getDistinguish(val,true)" clearable v-model="AddressDate.city_code"
                                   placeholder="--选择城市--">
                            <el-option
                                v-for="item in provicenMark"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                            </el-option>
                        </el-select>
                        &nbsp;
                        <el-select @change="getDistrick" v-model="AddressDate.district_code" placeholder="--选择地区--">
                            <el-option
                                v-for="item in DistinguishMark"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                            </el-option>
                        </el-select>
                        <p style="color: red" v-if="getVisible===true">请选择省，市，区</p>
                    </el-form-item>
                    <el-form-item label="详细地址" prop="receiver_detail">
                        <el-input v-model="AddressDate.receiver_detail"></el-input>
                    </el-form-item>
                    <el-form-item label="是否默认">
                        <el-switch
                            v-model="AddressDate.is_default">
                        </el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogAddress = false">取 消</el-button>
                    <el-button type="primary" @click="postAddress(AddressDate.address_id)">确 定</el-button>
                </span>
            </el-dialog>

        </div>

    </div>
</template>
<style>
/*背景渐变*/
.minwGet {
    max-height: 700px;
    overflow-y: auto;
}

.defaultSty {
    color: #000000;
    text-decoration: none;
    font-size: 14px;
    border: none;
    /*background: none;*/
    /*font-weight: 500;*/
    /*font-family: 'Poppins', sans-serif;*/
}

.defaultSty::before {
    margin-left: auto;
}

.defaultSty::after, .defaultSty::before {
    content: '';
    width: 0%;
    height: 2px;
    background: #0c27eb;
    display: block;
    transition: 0.5s;
}

.defaultSty:hover::after, .defaultSty:hover::before {
    width: 100%;
}

.marginget {
    display: flex;
    margin-top: -40px;
    justify-content: flex-end;
}

.box-w {
    /*-webkit-box-flex: 0;*/
    /*display: block;*/
    /*flex: 0 0 23%;*/
    /*max-width: 50%;*/
    border-left: 3px solid #3a5be6 !important;
}

.bg-wigetjt {
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    background-color: #3a5be6;
    justify-content: center;
    align-items: center;
    border: 1px solid #0c27eb !important;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}


.el-card__body {
    padding-top: 0 !important;
}


.item {
    margin-bottom: 18px;
}

.el-card__header {
    border: none !important;
}

</style>