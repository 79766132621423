<template>
    <div v-loading.fullscreen.lock="jumpTrue"></div>
</template>

<script>
export default {
    name: "jumpGet",
    data() {
        return {
            jumpTrue: true
        }
    },
    watch: {},
    mounted() {
        if (this.$route.query.token) {
            this.jumpTrue = false
            const token = this.$route.query.token
            localStorage.setItem('token', token)
            this.$router.push({path: '/gotHome'});
        }
    },
    methods:{

    }

}
</script>

<style scoped>

</style>