<script>
export  default {
    name: 'StoreManagement',
    data(){
        return{
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }],
            currentPge:1,
            diaEmpower:false,
            EmpowerDate:{
                code:''
            }
        }
    },
    methods:{

    }
}
</script>
<template>
    <div>
        <div class="bg-white rounded shadow ">
                        <div class="mx-8 pt-6">
                            <el-button  type="primary">授权新店铺</el-button>
                            <el-button @click="diaEmpower=true">通过授权新增店铺</el-button>
                        </div>
                        <div class="mx-8 pt-6">
                            <el-table
                                :data="tableData"
                                style="width: 100%">
                                <template slot="empty">
                                    <el-empty ></el-empty>
                                </template>
                                <el-table-column
                                    prop="date"
                                    label="店铺ID"
                                    width="180">
                                </el-table-column>
                                <el-table-column
                                    prop="name"
                                    label="店铺信息"
                                    width="180">
                                </el-table-column>
                                <el-table-column
                                    prop="name"
                                    label="店铺信息"
                                    width="180">
                                </el-table-column>
                                <el-table-column
                                    prop="nickname"
                                    label="店铺用户名">
                                </el-table-column>
                                <el-table-column
                                    prop="updateTime"
                                    label="到期时间">
                                </el-table-column>
                            </el-table>
                        </div>
            <div class="mx-8 pt-6 flex flex-col items-center">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPge"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="400">
                </el-pagination>
            </div>

        </div>
        <el-dialog
            title="新增授权店铺"
            :visible.sync="diaEmpower"
            width="40%">
           <div>
               <div class="bg-blue-50 p-3 my-3 rounded-md ">
                   <i  class="el-icon-warning-outline font-bold text-blue-700"></i> <span class="mx-2" style="color: #606266">您的授权码：324354624</span><el-button class="shadow-lg"  size="mini">复制</el-button>
               </div>
               <el-form ref="form" :model="EmpowerDate" label-width="80px">
                   <el-form-item label="授权码">
                       <el-input v-model="EmpowerDate.code" placeholder="填写他人分享给你的店铺授权码"></el-input>
                   </el-form-item>
               </el-form>


           </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="diaEmpower = false">取 消</el-button>
                <el-button type="primary" @click="diaEmpower = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style>
</style>