<script>
import {
    addLink,
    addSku, delGoods,
    delSku,
    disGoodSIndex, editSku, getGoodsList,
    goodLink,
    goodsSave,
    goodsSyn,
    goodsSync, setGoods, setSku,
} from "@/api/department";
import {loop} from "@/utils/antiShake";
import JT from '@/assets/pages/jT.png'
import {Loading} from "element-ui";
import noborder from "@/assets/pages/noborder.png"
// import _ from "lodash"
export default {
    name: 'DistributionGoods',
    data() {
        return {
            getTableLOading: false,
            getlopr: true,
            noborder: noborder,
            JT: JT,
            SkuVisible: false,
            getData: [
                {
                    id: "",
                    link_remarks: '',
                    good_url: '',
                    least_buy_num: 1,
                }
            ],
            StatusDate: [
                {value: 1, label: '在售'},
                {value: 0, label: '下架'}
            ],
            CommodityMana: {
                ProductTitle: '',
                ProductStatus: 0,
                goods_status: ""
            },
            disGoodList: [],
            multipleSelection: [],
            translateX: 0,
            surplusWLeft: 0,
            surplusWRight: 0,
            liList: [],
            hiddenButon: false,
            linkLiST: [],
            disGoodListTotal: 0,
            currentPage: 1,
            limite: 10,
            screenshotVisible: false,
            getToolti: false,
            pageList: [
                {
                    label: '10条/页',
                    value: 10
                }, {
                    label: '20条/页',
                    value: 20
                }, {
                    label: '30条/页',
                    value: 30
                }, {
                    label: '40条/页',
                    value: 40
                }, {
                    label: '50条/页',
                    value: 50
                },
            ],
            skuData: [],
            product_id: '',
            activeName: 0,
            choiceText: '',
            num: 1,
            productNum: 1,
            bindProduct: {},
            productMessage: {},
            least_buy_num: 1,
            link_remarks: '',
            pos: 0,
            merferItems: [
                {
                    columnIndex: 0,
                    spanArr: [],
                    pos: 0,
                    prop: 'spec_text'
                }
            ],
            action: 1,
            timerI: null,
            autofocus: false,
            timer: null,
        }
    },
    watch: {
        currentPage: {
            handler(val) {
                this.getTableLOading = true
                if (this.disGoodListTotal !== 0) {
                    const num = Math.ceil(this.disGoodListTotal / this.limite)
                    if (val < 1) {
                        this.currentPage = 1
                    } else if (val > num) {
                        this.currentPage = num
                    } else {
                        if (this.timerI != null) {
                            clearTimeout(this.timerI)
                        }
                        this.currentPage = val
                        this.timerI = setTimeout(() => {
                            this.getPost()
                        }, 1000)
                    }
                } else {
                    this.currentPage = 1
                }

            }
        },
        limite: {
            handler(val) {
                this.currentPage = 1;
                let timerGet = null
                if (timerGet != null) {
                    clearTimeout(timerGet)
                }
                this.limite = val
                timerGet = setTimeout(() => {
                    this.getPost()
                    timerGet = null
                }, 1000)
            }
        },
        product_id: {
            handler(val) {
                const data = this.disGoodList.find(el => {
                    return el.product_id === val
                })
                if (data) {
                    this.taoT(data.img)
                }

            }
        }
    },
    activated() {
        let loadingInstance = null;
        loadingInstance = Loading.service({
            lock: true,
            text: "正在拼命加载中...",
            background: "rgba(0,0,0,0.8)"
        });

        if (this.$route.query.order_id && this.$route.query.order_id !== this.CommodityMana.ProductTitle) {
            this.CommodityMana.ProductTitle = this.$route.query.order_id
            this.CommodityMana.goods_status = ""
            this.getPost()
            this.getMessage(this.$route.query.order_id)

        } else {
            this.getPost()
            const data = sessionStorage.getItem("product_id")
            if (data) {
                this.getMessage(data)
            }
        }
        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
        });

    },
    methods: {
        taoT(val) {
            const data = {
                img_url: val
            }
            getGoodsList(data).then(res => {
                if (res.code === 1) {
                    this.liList = res.data.map(item => {
                        item.hiddenButon = false
                        return item
                    })
                    this.$notify({
                        title: res.msg,
                        type: 'success'
                    })
                } else if (res.code === 106) {
                    this.liList = []
                    this.diaVisible = false
                    this.$message.warning(res.msg)
                    // setTimeout(() => {
                    //     this.$router.push({path: '/PurchaseAccount'})
                    // }, 1000)
                } else {
                    this.liList = []
                    this.$notify({
                        title: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        getJumpID() {
            this.screenshotVisible = false
            document.getElementById('js-lipei').scrollIntoView({
                block: 'start',
                behavior: 'smooth' // 代表平滑滚动
            });
        },
        getPost() {
            const data = {
                page: this.currentPage,
                limit: this.limite,
                name: this.CommodityMana.ProductTitle,
                goods_status: this.CommodityMana.goods_status
            }
            disGoodSIndex(data).then(res => {

                if (res.code === 1) {

                    this.disGoodListTotal = res.data.total
                    this.disGoodList = res.data.data.map(item => {
                        item.status = false
                        return item
                    })
                    if (this.product_id !== "") {
                        const data = this.disGoodList.find(el => {
                            return el.product_id === this.product_id
                        })
                        if (data) {
                            this.taoT(data.img)
                        }
                    }

                    if (this.product_id !== "") {
                        this.getProduct();
                    }
                    this.getlopr = false
                    this.getTableLOading = false
                } else {
                    this.getTableLOading = false
                    this.$notify({
                        type: 'error',
                        message: res.msg
                    })
                }

            })
        },
        getProduct(val) {
            const pro = val ? val : this.product_id
            this.disGoodList = this.disGoodList.map(item => {
                item.status = false
                return item
            })
            const index = this.disGoodList.findIndex(el => el.product_id === pro)
            if (index !== -1) this.disGoodList[index].status = true

        },
        onSubmit() {
            this.getTableLOading = true
            this.getPost()
        },
        getMessage(val) {
            this.getlopr = true
            const data = {product_id: val}
            sessionStorage.setItem("product_id", val)
            this.product_id = val
            goodLink(data).then(res => {
                this.getlopr =false
                if (res.code == 1) {
                    const datasBase = [
                        {
                            id: 1,
                            link_remarks: '',
                            good_url: '',
                            least_buy_num: 1,
                        }]
                    if (res.data == null) {
                        this.product_id = ""
                        this.skuData = []
                        this.getData = datasBase
                        this.linkLiST = []
                    } else {
                        this.skuData = res.data.skuData.length === 0 ? [] : loop(res.data.skuData)
                        this.skuData.forEach(item => {
                            for (const key in item) {
                                item[key].choice = false
                            }
                        })
                        this.getData = res.data.goods_bind_url.length == 0 ? datasBase : res.data.goods_bind_url
                        if (res.data.goods_sku_many_bind.length === 0) {
                            this.linkLiST = []
                        } else {
                            let goodsSku = []
                            goodsSku = res.data.goods_sku_many_bind
                            this.getSpanArr(goodsSku, this.merferItems)
                            this.linkLiST = goodsSku
                        }
                    }
                } else {
                    this.$notify.error({
                        title: res.msg,
                    });
                }

            })
        },
        getSpanArr(data, array) {//循环数据处理
            for (let n = 0; n < array.length; n++) {
                array[n].spanArr = []//数据清空(重新调此方法的时候需要清空上一次的数据)
                for (let i = 0; i < data.length; i++) {
                    if (i === 0) {
                        array[n].spanArr.push(1);
                        array[n].pos = 0;
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (data[i][array[n].prop] === data[i - 1][array[n].prop]) {
                            array[n].spanArr[array[n].pos] += 1;
                            array[n].spanArr.push(0);
                        } else {
                            array[n].spanArr.push(1);
                            array[n].pos = i;
                        }
                    }
                }
            }
            return array
        },
        goodSync(val) {
            const data = {id: val.id}
            goodsSync(data).then(res => {
                if (res.code == 1) {
                    this.$message.success(res.msg)
                    this.getPost()
                    this.getMessage(this.product_id, this.goods_sku_id)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        async getLink(val) {
            const data = {
                product_id: this.product_id,
                url: val,
            }
            await addLink(data).then(res => {
                if (res.code == 1) {
                    this.$message.success(res.msg)
                    this.getPost()
                    this.getMessage(this.product_id, this.goods_sku_id)
                } else {
                    this.$message.error(res.msg)
                }
            })


        },
        getSkuDate() {

            this.skuData.forEach(item => {
                for (const key in item) {
                    item[key].choice = false
                }
            })
        },
        choice(val, index) {
            this.getSkuDate()
            this.skuData[0][index].choice = true
            this.skuData = loop(this.skuData, val.attributesText)
            this.choiceText = val.attributesText
            this.bindProduct = val
        },
        handleNum(value) {
            this.productNum = value
        },
        handleClick() {
        },
        postAdd() {
            this.SkuVisible = false
            let num1 = 1
            const num = loop(this.skuData, this.productMessage.bind_sku.sku_text, true)
            const maxnum = JSON.stringify(this.bindProduct) == "{}" ? num : this.bindProduct.amountOnSale
            if (this.productNum > maxnum) {
                num1 = maxnum
            } else {
                num1 = this.productNum
            }
            if (this.action === 1) {
                //修改
                let data = {}
                const hiden = JSON.stringify(this.bindProduct) == "{}"
                if (hiden) {

                    data = {
                        sku_id: this.productMessage.bind_sku.sku_id,
                        sku_text: this.productMessage.bind_sku.sku_text,
                        goods_bind_url_id: this.productMessage.bind_sku.goods_bind_url_id,
                        id: this.productMessage.bind_sku.id,
                        img: this.productMessage.bind_sku.img,
                        specId: this.bindProduct.specId,
                        num: num1
                    }
                } else {
                    data = {
                        sku_id: this.bindProduct.skuId,
                        sku_text: this.bindProduct.attributesText,
                        goods_bind_url_id: this.bindProduct.id,
                        id: this.productMessage.bind_sku.id,
                        specId: this.bindProduct.specId,
                        img: this.bindProduct.img,
                        num: num1
                    }
                }
                this.bindProduct = {}
                editSku(data).then(res => {
                    if (res.code == 1) {
                        this.$message.success(res.msg)
                        this.getPost()
                        this.getMessage(this.product_id, this.goods_sku_id)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else if (this.action === 2) {
                //新增
                const data = {
                    product_id: this.productMessage.product_id,
                    goods_sku_id: this.productMessage.sku_id,
                    sku_id: this.bindProduct.skuId,
                    sku_text: this.bindProduct.attributesText,
                    goods_bind_url_id: this.bindProduct.id,
                    specId: this.bindProduct.specId,
                    img: this.bindProduct.img,
                    num: num1
                }
                this.bindProduct = {}
                addSku(data).then(res => {
                    if (res.code == 1) {
                        this.$message.success(res.msg)
                        this.getPost()
                        this.getMessage(this.product_id, this.goods_sku_id)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }


        },
        getID(val, el) {
            if (this.getData[0].good_url === "") {
                document.getElementById('js-lipei').scrollIntoView({
                    block: 'start',
                    behavior: 'smooth' // 代表平滑滚动
                });
                this.$refs['autofocus1'].$refs.input.focus();
                this.getToolti = true;
                setTimeout(() => {
                    this.$refs['autofocus1'].$refs.input.blur();
                    this.getToolti = false
                }, 5000)
            } else {
                this.skuData = loop(this.skuData)
                if (el == 1) {
                    //修改
                    this.num = val.bind_sku.num
                    this.choiceText = val.bind_sku.sku_text
                    this.skuData = loop(this.skuData, val.bind_sku.sku_text)
                    const data = this.skuData[0].find(el => {
                        return el.attributesText === val.bind_sku.sku_text
                    })
                    this.bindProduct = data
                    this.action = 1
                } else if (el == 2) {
                    //新增
                    this.choiceText = ''
                    this.action = 2
                }
                this.productMessage = val
                this.SkuVisible = true
            }

        },
        AddUrl() {
            let index = this.getData.length + 1
            this.getData.push({
                index: index,
                id: '',
                link_remarks: '',
                good_url: '',
                least_buy_num: '',
            })
        },
        copyValue(val) {
            const that = this;
            this.$copyText(val).then(
                function () {
                    that.$message({
                        message: "复制成功!",
                        type: 'success'
                    });
                },
                function () {
                    that.$message({
                        message: "复制失败!",
                        type: 'error'
                    });
                }
            );
        },
        delectLink(val) {
            if (val.good_url === "" && val.id === "") {
                this.getData.pop()
            } else {
                const data = {
                    id: val.id
                }
                delGoods(data).then(res => {
                    if (res.code == 1) {
                        this.getPost()
                        this.getMessage(this.product_id, this.goods_sku_id)
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },

        setLink(val) {
            if (val.good_url == "") {
                this.$message.warning('请输入商品链接')
            } else {
                if (this.timer) return
                this.timer = setTimeout(() => {
                    const data = {
                        product_id: this.product_id,
                        url: val.good_url,
                        id: this.getData[0].product_id ? val.id : "",
                        least_buy_num: val.least_buy_num,
                        link_remarks: val.link_remarks,
                    }
                    goodsSave(data).then(res => {
                        if (res.code == 1) {
                            this.$message.success(res.msg)
                            this.getPost()
                            this.getMessage(this.product_id, this.goods_sku_id)
                            // this.timer = null
                        } else {
                            this.$message.error(res.msg)
                        }
                    }).catch(() => {
                    }).finally(() => {
                        this.timer = null
                    })
                })
            }

        },
        deleteId(val) {
            const data = {id: val.bind_sku.id}
            delSku(data).then(res => {
                if (res.code == 1) {
                    this.$message.success(res.msg)
                    this.getPost()
                    this.getMessage(this.product_id, this.goods_sku_id)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        objectSpanMethod({rowIndex, columnIndex}) {
            if (columnIndex === 0) {
                let item = this.merferItems.find(item => item.columnIndex === columnIndex);
                const _row = item.spanArr[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col
                }
            }
        },
        getMessages(row) {
            this.getProduct(row.product_id)
            localStorage.setItem('getRow', JSON.stringify(row))
            const index = this.disGoodList.findIndex(el => el.product_id === row.product_id)
            this.disGoodList[index].status = true
            this.getMessage(row.product_id, row.goods_sku_id)
        },
        syncData(val) {

            const data = {
                type: val?val:""
            }
            console.log(data)
            goodsSyn(data).then(res => {
                if (res.code == 1) {
                    this.$notify({
                        message: res.msg,
                        type: 'success'
                    })
                    setTimeout(() => {
                        this.onSubmit();
                    }, 3000)
                } else if (res.code == 3) {
                    this.$notify({
                        message: res.msg,
                        type: 'warning'
                    })
                } else {
                    this.$notify({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        screenshot() {
            this.SkuVisible = false;
            this.screenshotVisible = true
        },
        getDefault(val, num) {
            let data = {}
            if (num === 1) {
                data = {id: val.id, is_default: num}
            } else {
                data = {id: val.id, is_default: num}
            }
            setGoods(data).then(res => {
                if (res.code == 1) {
                    this.$message.success(res.msg)
                    this.getPost()
                    this.getMessage(this.product_id, this.goods_sku_id)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        getIsDefault(val, num) {
            const data = {id: val.bind_sku.id, is_default: num}
            setSku(data).then(res => {
                if (res.code == 1) {
                    this.$message.success(res.msg)
                    this.getPost()
                    this.getMessage(this.product_id, this.goods_sku_id)
                } else {
                    this.$message.error(res.msg)
                }

            })
        },
        getLeft(val) {
            const outWidth = this.$refs.outInt.clientWidth;
            const inWidth = this.$refs.inInt.clientWidth;
            console.log(inWidth)
            console.log(outWidth)
            //右移+左移-
            if (val == 1 && this.surplusWRight >= -1 && this.translateX !== 0) {
                if (this.surplusWRight >= -1) {
                    this.translateX = this.translateX + outWidth
                    this.surplusWRight = this.surplusWRight - 1
                }
            } else if (val == 2 && this.surplusWLeft >= 0 && inWidth > outWidth) {
                let get = 0
                get = (-1) * (inWidth / outWidth) * outWidth + outWidth
                if (this.surplusWRight > 1 && this.translateX > get) {
                    let get = 0
                    get = this.translateX + (-1) * outWidth;
                    this.translateX = get
                    this.surplusWRight = this.surplusWRight - 1
                } else if (this.surplusWRight == 0 || this.translateX == 0) {
                    if (this.translateX == 0) {
                        this.surplusWRight = 0
                    }
                    this.surplusWRight = Math.ceil(inWidth / outWidth);
                    this.translateX = (-1) * outWidth;
                }
            }
        },
        changgeButon(val, id) {
            if (val == 1) {
                this.liList.find(el => el.id == id).hiddenButon = true
            } else {
                this.liList.find(el => el.id == id).hiddenButon = false
            }

        },
        getLOse(val) {
            // _.throttle(
            //
            //       ,2000,
            //
            // )
            this.setLink(val)
        }


    }
}
</script>
<template>
    <div class="bg-white bgWidth rounded shadow ">
        <div class="card">
            <a class="card1" target="_blank" href="https://fuwu.jinritemai.com/detail?service_id=22318&from=isv.detail">
                <p>温馨提示：使用<span class="cursor-pointer font-extrabold text-blue-800 mx-0.5">牛牛搬家</span>铺货可同步商品的货源库,自动绑定商品对应的规格
                </p>
                <!--                <p class="small"></p>-->
                <div class="go-corner" href="#">
                    <div class="go-arrow">
                        →
                    </div>
                </div>
            </a>
        </div>
        <div class=" px-4 pt-4 flex flex-wrap ">
            <el-form :inline="true" ref="form" :model="CommodityMana" label-width="80px">
                <el-form-item label="商品标题">
                    <el-input v-model="CommodityMana.ProductTitle" clearable
                              placeholder="请输入商品名称或者商品id"></el-input>
                </el-form-item>
                <el-form-item label="商品状态">
                    <el-select v-model="CommodityMana.goods_status" clearable placeholder="请选择">
                        <el-option
                            v-for="item in StatusDate"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                </el-form-item>
            </el-form>
            <div class="mt-1">
                <el-button-group>
                    <!--                    <el-button type="primary" @click="onSubmit">导入关联链接</el-button>-->
                    <el-popover
                        placement="right-start"
                        width="200"
                        trigger="hover">
                        <div class="flex flex-col items-center">

                            <div class="py-3 getBU border-b " @click="syncData(1)">
                                <i class="el-icon-s-goods">在销商品</i>
                            </div>
                            <div @click="syncData( )" class="getBU py-3">
                                <i class="el-icon-delete-solid">全部商品</i>
                            </div>
                        </div>
                        <el-button type="primary" slot="reference"><i class="el-icon-sort"></i>同步商品</el-button>
                    </el-popover>
                </el-button-group>
            </div>


        </div>

        <div class="px-4 getPArent ">
            <div class="getBox" v-if="screenshotVisible" @click="getJumpID">
                <div class="getOk">
                    <el-image fit="fill" :src="JT">
                    </el-image>
                </div>
                <div class="flex-col flex items-center justify-center getTo">
                    <el-button type="primary">去设置</el-button>
                </div>
            </div>
            <div class="flex pb-2 positionPa">
                <div class="positionSon">
                    <div @click="onSubmit"><i class="el-icon-refresh mr-4 font-semibold text-xl text-slate-400"></i>
                    </div>
                    <el-select v-model="limite" placeholder="请选择">
                        <el-option
                            v-for="item in pageList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="table-1 getLeft">
                    <el-table
                        v-loading="getTableLOading"
                        @row-click="getMessages"
                        max-height="670px"
                        :data="disGoodList"
                        style="width: 100%"
                    >
                        <template slot="empty">
                            <el-empty></el-empty>
                        </template>
                        <el-table-column
                            align="center"
                            width="130"
                            prop="name"
                            label="本店宝贝信息">
                            <template v-slot:default="scope">
                                <el-popover
                                    placement="top-start"
                                    width="230"
                                    trigger="hover"
                                >
                                    <div>
                                        <el-image slot="reference" style="width: 210px;height:210px"
                                                  :src="scope.row.img">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline-round"></i>
                                            </div>
                                        </el-image>
                                    </div>
                                    <el-image slot="reference"
                                              style="width: 90px; height:90px"
                                              :src="scope.row.img"
                                              fit="fill">

                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-picture-outline-round"></i>
                                        </div>
                                    </el-image>

                                </el-popover>

                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template v-slot:default="scope">
                                <div class=" mr-2">
                                    <div class=" flex flex-col mx-2">
                                        <span>{{ scope.row.name }}</span>
                                        <span class="text-red-600">￥{{ scope.row.market_price }}</span>
                                    </div>
                                    <div class="float-right">
                                        <span class="text-red-500 mr-2">已关联SKU：{{scope.row.bind_sku_count}}/{{scope.row.goods_dis_sku_one_count
                                            }}</span>
                                        <button class="buttonPadding" :class="{'buttonPa': scope.row.status}"
                                                type="text">
                                            <span>绑定</span>
                                        </button>
                                    </div>
                                </div>

                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="flex-col justify-center">
                        <div class=" inputW mt-5 flex justify-center items-center">
                            <el-button type="text" @click="currentPage-=1">
                                <i class="el-icon-arrow-left"></i>
                            </el-button>
                            <input
                                oninput="value=value.replace(/[^\d]/g,'')"
                                type="text"
                                class="getInput"
                                placeholder="请输入内容"
                                v-model="currentPage"/>
                            <span class="px-1">/</span>
                            <span>{{ Math.ceil(disGoodListTotal / limite) }}</span>
                            <el-button @click="currentPage+=1" type="text">
                                <i class="el-icon-arrow-right"></i>
                            </el-button>
                            <span class="text-base">共<span class="mx-1">{{ disGoodListTotal }}</span>条</span>
                        </div>
                    </div>

                </div>
                <div class="getRight">
                    <div class="h-title ">
                        <span>关联下单链接</span>
                    </div>
                    <el-empty :image="noborder" v-if="product_id===''" :image-size="300">
                        <span slot="description" class="font-bold text-2xl"><i
                            class="el-icon-back"></i>请选择左侧商品进行绑定</span>
                    </el-empty>

                        <div  v-if="product_id!==''" class="p-2 wSool ">
                            <div element-loading-background="rgba(0, 0, 0, 0.8)" element-loading-text="拼命加载中"
                                 v-loading="getlopr">
                            <div class="border p-2 rounded">
                            <span id="js-lipei"
                                  class="text-lg text-gray-500">货源库（目前支持绑定1688或淘特的货源）</span>
                                <div class="my-2" v-if=" getData[0].good_url===''">
                                    <el-alert
                                        title="提示：请输入下单地址链接"
                                        type="warning"
                                        show-icon
                                        :closable="false">
                                    </el-alert>
                                </div>
                                <el-table
                                    :data="getData"
                                    style="width: 100%">
                                    <template slot="empty">
                                        <el-empty></el-empty>
                                    </template>
                                    <el-table-column
                                        prop="link_remarks"
                                        align="center"
                                        label="链接备注">

                                        <template v-slot:default="scope">
                                            <div class="px-1">
                                                <el-input v-model="scope.row.link_remarks" placeholder="可为空">
                                                </el-input>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="good_url"
                                        label="货源链接"
                                        width="300"
                                    >
                                        <template v-slot="scope">

                                            <div style="font-size: 13px" v-if="scope.row.product_id"
                                                 class="broderImAGE px-1 justify-around flex items-center">
                                                <el-popover
                                                    placement="top-start"
                                                    width="230"
                                                    trigger="hover"
                                                >
                                                    <div>
                                                        <el-image slot="reference" style="width: 210px;height:210px"
                                                                  :src="scope.row.imager">
                                                            <div slot="error" class="image-slot">
                                                                <i class="el-icon-picture-outline-round"></i>
                                                            </div>
                                                        </el-image>
                                                    </div>
                                                    <el-image slot="reference" style="width: 60px;height: 60px"
                                                              :src="scope.row.imager">
                                                        <div slot="error" class="image-slot">
                                                            <i class="el-icon-picture-outline-round"></i>
                                                        </div>
                                                    </el-image>
                                                </el-popover>

                                                <div class=" flex flex-col items-start mx-1 ">
                                                    <p style="line-height: 16px" class="text-blue-400">
                                                        <a :href="scope.row.good_url" target="_blank">{{
                                                                scope.row.subject
                                                            }}</a> {{ scope.row.is_default }}
                                                    </p>
                                                    <div>
                                                        <span>ID:{{ scope.row.product_id }}</span>
                                                        <!--                                                    <el-button type="text"-->
                                                        <!--                                                               @click="()=>copyValue(scope.row.product_id)"-->
                                                        <!--                                                               size="mini">-->
                                                        <!--                                                        <i class="el-icon-document-copy"></i>-->
                                                        <!--                                                    </el-button>-->
                                                    </div>
                                                    <div class="flex  items-center">

                                                        <div class="whitespace-nowrap getHIen">
                                                            <i
                                                                class="el-icon-s-goods text-2xl text-blue-700"></i>{{
                                                                scope.row.seller_login_id
                                                            }}
                                                        </div>
                                                        <span class=" ml-2 spanStyle bg-blue-800 rounded text-white p-0.5"
                                                              v-if="scope.row.is_default===1">
                                                    {{ scope.row.is_default === 1 ? "默认发货" : "" }}
                                                </span>
                                                    </div>
                                                </div>

                                            </div>

                                            <div v-else class="px-1">
                                                <el-tooltip :value="scope.row.id=='1'?getToolti:false" :manual="true"
                                                            content="请输入下单地址链接"
                                                            placement="top">
                                                    <el-input  :class="{'getborder':getToolti}" :ref="'autofocus'+scope.row.id" @blur="setLink(scope.row)"
                                                              type="text"
                                                              clearable
                                                              v-model="scope.row.good_url"
                                                              placeholder="请输入下单地址链接">
                                                    </el-input>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="least_buy_num"
                                        label="最低采购件数"
                                        align="center"
                                    >
                                        <template v-slot:default="scope">
                                            <div class="witNumber px-0.5">
                                                <el-input-number
                                                    v-model="scope.row.least_buy_num" controls-position="right"
                                                    :min="1" :max="10"></el-input-number>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        fixed="right"
                                        align="center"
                                        label="操作">
                                        <template v-slot:default="scope">
                                            <div class=" flex   flex-wrap ">
                                                <el-button type="text" @click="setLink(scope.row)">
                                                    保存
                                                </el-button>
                                                <el-button v-if="scope.row.is_default===2" @click="getDefault(scope.row,1)"
                                                           type="text">
                                                    设为默认
                                                </el-button>
                                                <el-button v-if="scope.row.is_default===1" @click="getDefault(scope.row,2)"
                                                           type="text">
                                                    取消默认
                                                </el-button>
                                                <el-button v-if="scope.row.product_id" @click="goodSync(scope.row)"
                                                           type="text">
                                                    源同步
                                                </el-button>

                                                <el-button :disabled="(getData.length===1)&&(getData[0].good_url=='')"
                                                           @click="(index)=>delectLink(scope.row,index)" type="text"
                                                           size="small">
                                                    删除
                                                </el-button>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="my-2">
                                    <el-button @click="AddUrl" type="primary">新增货源链接</el-button>
                                </div>
                            </div>
                            <div class="bgW p-1">
                                <div>
                                    <el-button type="text">推荐货源--淘特</el-button>
                                    <el-divider direction="vertical"></el-divider>
                                    <span>一件代发，包邮，48小时发货</span>
                                </div>
                                <div class="getFault pb-3 flex items-center justify-between">
                                    <el-button :disabled="liList.length===0" class="getCorl" circle @click="getLeft(1)">
                                        <i class="el-icon-arrow-left"></i>
                                    </el-button>
                                    <el-empty class="getP0" description="暂未匹配到货源" :image-size="50" v-if="liList.length===0">
                                    </el-empty>
                                    <div v-if="liList.length!==0" class=" wFixed overflow-hidden" ref="outInt">
                                        <div ref="inInt"
                                             class=" getposition list-none flex overflow-x-hidden "
                                             :style="{
                                                            transform: 'translateX(' + translateX + 'px)',
                                                            }">
                                            <li @mouseenter="changgeButon(1,item.id)" @mouseleave="changgeButon(2,item.id)"
                                                :key="item.id" v-for="item in liList" class="mx-1 wGli">
                                                <div class="index1">
                                                    <el-image fit="fill" :src="item.itemSquarePic">
                                                        <div slot="error" class="image-slot">
                                                            <i class="el-icon-picture-outline-round"></i>
                                                        </div>
                                                    </el-image>
                                                </div>
                                                <div class="index2">
                                                    <div class="labelTy">
                                                    <span class="limitMultiLine">
                                                        <div class="flex justify-between">
                                                            <span class=" limitMultiLins">
                                                                {{ item.itemSales }}
                                                            </span>
                                                         <span style="color: red">
                                                    ￥{{ item.itemPrice }}
                                                </span>
                                                        </div>{{
                                                            item.itemTitle
                                                        }}</span>
                                                    </div>
                                                    <div
                                                        class="flex flex-col items-center justify-center cursor-pointer marginGet"
                                                        v-if="item.hiddenButon==true">

                                                        <div class="mb-1">
                                                            <div @click="getLink(item.url)" class=" getHIDEN">加入关联链接
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </li>
                                        </div>
                                    </div>
                                    <el-button class="getCorl" :disabled="liList.length===0" circle @click="getLeft(2)"><i
                                        class="el-icon-arrow-right"></i></el-button>
                                </div>
                            </div>
                            <div>
                                <div class="my-2" v-if=" getData[0].good_url===''">
                                    <el-alert
                                        title="提示：请关联商家SKU"
                                        type="warning"
                                        show-icon
                                        :closable="false">
                                    </el-alert>
                                </div>
                                <div class="my-2 pb-3">
                                    <el-table
                                        :span-method="objectSpanMethod"
                                        border
                                        :data="linkLiST"
                                        style="width: 100%">
                                        <template slot="empty">
                                            <el-empty></el-empty>
                                        </template>
                                        <el-table-column
                                            props="spec_text"
                                            label="本店宝贝"
                                            align="center"
                                            fixed>
                                            <template v-slot:default="scope">
                                                <div class="px-1 flex justify-start ml-2 items-center">
                                                    <el-popover
                                                        v-if="scope.row.img!==''"
                                                        placement="top-start"
                                                        width="230"
                                                        trigger="hover"
                                                    >
                                                        <div>
                                                            <el-image slot="reference" style="width: 210px;height:210px"
                                                                      :src="scope.row.img">
                                                                <div slot="error" class="image-slot">
                                                                    <i class="el-icon-picture-outline-round"></i>
                                                                </div>
                                                            </el-image>
                                                        </div>
                                                        <el-image slot="reference" style="width: 50px;height: 50px"
                                                                  :src="scope.row.img">
                                                            <div slot="error" class="image-slot">
                                                                <i class="el-icon-picture-outline-round"></i>
                                                            </div>
                                                        </el-image>
                                                    </el-popover>
                                                    <!--                                                <el-image style="width: 50px;height: 50px" :src="scope.row.img">-->
                                                    <!--                                                    <div slot="error" class="image-slot">-->
                                                    <!--                                                        <i class="el-icon-picture-outline-round"></i>-->
                                                    <!--                                                    </div>-->
                                                    <!--                                                </el-image>-->
                                                    <span class="ml-1.5">{{ scope.row.spec_text }}</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            align="center"
                                            prop="bind_sku"
                                            label="上架宝贝">
                                            <template v-slot:default="scope">
                                                <div v-if="scope.row.bind_sku.length!==0"
                                                     class="px-1 flex justify-start ml-2  items-center">
                                                    <el-popover
                                                        placement="top-start"
                                                        width="230"
                                                        trigger="hover"
                                                    >
                                                        <div>
                                                            <el-image slot="reference" style="width: 210px;height:210px"
                                                                      :src="scope.row.bind_sku.img">
                                                                <div slot="error" class="image-slot">
                                                                    <i class="el-icon-picture-outline-round"></i>
                                                                </div>
                                                            </el-image>
                                                        </div>
                                                        <el-image slot="reference" style="width: 50px;height: 50px"
                                                                  :src="scope.row.bind_sku.img">
                                                            <div slot="error" class="image-slot">
                                                                <i class="el-icon-picture-outline-round"></i>
                                                            </div>
                                                        </el-image>
                                                    </el-popover>

                                                    <div class="flex ml-1.5  justify-start items-start flex-col ml-2">
                                                    <span>{{ scope.row.bind_sku.sku_text }}
                                                        <span
                                                            class=" ml-2 spanStyle bg-blue-800 rounded text-white p-0.5"
                                                            v-if="scope.row.bind_sku.is_default===1">
                                                    {{ scope.row.bind_sku.is_default === 1 ? "默认发货" : "" }}
                                                </span></span>

                                                    </div>
                                                </div>
                                                <div v-else><span class="text-orange-500">该规格还未绑定对应商品,</span>
                                                    <el-button @click="getID(scope.row,2)" type="text">去绑定</el-button>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            align="center"
                                            prop="bind_sku.num"
                                            label="单次最低采购件数"
                                            width="80"
                                        >
                                            <template v-slot:default="scope">
                                                <div class="">
                                                    {{ scope.row.bind_sku.num }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            align="center"
                                            fixed="right"
                                            width="120"
                                            label="操作"
                                        >
                                            <template v-slot:default="scope">
                                                <div class="flex justify-around flex-wrap px-2">
                                                    <el-button v-if="scope.row.bind_sku.sku_text!==undefined"
                                                               @click="getID(scope.row,1)" type="text" size="small">
                                                        修改
                                                    </el-button>
                                                    <el-button v-if="scope.row.bind_sku.is_default===2"
                                                               @click="getIsDefault(scope.row,1)" type="text"
                                                               size="small">
                                                        设为默认
                                                    </el-button>
                                                    <el-button v-if="scope.row.bind_sku.is_default===1"
                                                               @click="getIsDefault(scope.row,2)" type="text"
                                                               size="small">
                                                        取消默认
                                                    </el-button>
                                                    <el-button v-if="scope.row.bind_sku.sku_text!==undefined"
                                                               @click="deleteId(scope.row)" type="text" size="small">
                                                        删除
                                                    </el-button>
                                                    <el-button @click="getID(scope.row,2)" type="text" size="small">
                                                        新增
                                                    </el-button>


                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                            </div>
                            <el-dialog
                                :visible.sync="SkuVisible"
                                width="60%">
                                <div>
                                    <div class="flex justify-center items-center text-gray-500" v-if="skuData.length==0">
                                        该商品您还未绑定货源！
                                        <el-button type="text" @click="screenshot">去绑定货源</el-button>
                                    </div>
                                    <el-tabs v-if="skuData.length!==0" v-model="activeName" @tab-click="handleClick">
                                        <el-tab-pane :key="item.skuId" v-for="(item,index) in skuData">
                                            <span slot="label">默认货源{{ index }}</span>
                                            <!--&lt;!&ndash;                                        <span slot="name">index</span>&ndash;&gt;货源{{ index + 1 }}-->
                                            <div style="height: 57px">
                                                <el-alert
                                                    v-if="choiceText ==''"
                                                    title="请在下方选择规格!"
                                                    type="warning">
                                                </el-alert>
                                                <span v-if="choiceText !==''">已选择：{{ choiceText }}</span>
                                            </div>
                                            <div class="flex">
                                                <span>规格</span>
                                                <div class="widSco flex flex-wrap flex-row justify-start items-start p-1">
                                                    <div :class="{getwidSon:itemd.choice}" @click="choice(itemd,index)"
                                                         class=" m-1  widSon justify-start items-center flex border "
                                                         :key="itemd.skuId" v-for="(itemd,index) in skuData[index]">
                                                        <div class="p-1">
                                                            <el-image
                                                                style="height:50px;width: 50px"
                                                                :src="itemd.img">
                                                                <div slot="error" class="image-slot">
                                                                    <i class="el-icon-picture-outline-round"></i>
                                                                </div>
                                                            </el-image>
                                                        </div>
                                                        <div>{{ itemd.attributesText }}</div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex getNum">
                                                <span>数量</span>
                                                <div class="flex   justify-around items-center" style="height: 57px">
                                                    <el-input-number
                                                        v-model="num"
                                                        controls-position="right"
                                                        @change="(value)=>handleNum(value)" :min="1">
                                                    </el-input-number>
                                                </div>
                                            </div>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>
                                <span slot="footer" class="dialog-footer" v-if="skuData.length!==0">
                                <el-button @click="SkuVisible = false">取 消</el-button>
                                <el-button type="primary"
                                           @click="postAdd">{{ action === 1 ? '修改' : '添加' }}</el-button>
                            </span>
                            </el-dialog>
                        </div>

                </div>
            </div>
        </div>

    </div>
</template>
<style>
.getborder{
    border-radius: 0.3rem;
    border: 2px solid #7eb8f3 !important;
    background-color: #409eff;
}
.wSool ::v-deep .el-loading-mask{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999; /* 遮罩层级要比进度条高 */
}


.getHIDEN {
    border-radius: 0.2rem;
    padding: 0 4px;
    background-color: #0c27eb;
    color: white;
}

.getP0 {
    padding: 0 !important;
}

.card p {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: white;
}

.card p.small {
    font-size: 14px;
}

.go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #0C27EAFF;
    border-radius: 0 4px 0 32px;
}

.go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
}

.card1 {
    display: block;
    position: relative;
    /*max-width: 262px;*/
    width: 100%;
    background-color: #51972d;
    border-radius: 4px;
    padding: 18px 24px;
    /*margin: 12px;*/
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}

.card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #0C27EAFF;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: all 0.3s ease-out;
}

.card1:hover .go-corner {
    background: #0C27EAFF;
}

.card1:hover span {
    color: white;
}

.card1:hover:before {
    transform: scale(100);
}

.card1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}

.card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}

.card3 .go-corner {
    opacity: 0.7;
}

.card3:hover {
    border: 1px solid #00838d;
    box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
    z-index: 500;
}

.card3:hover p {
    color: #00838d;
}

.card3:hover .go-corner {
    transition: opactiy 0.3s linear;
    opacity: 1;
}


.getML {
    margin-top: -10px;
}

.getHIen {
    text-overflow: ellipsis;
    width: 100px;
    overflow: hidden;
}

.getBU {
    display: flex;
    justify-content: center;
    padding: 2px;
    width: 100%;
}

.getBU:hover {
    border-radius: 4px;
    width: 100%;
    background-color: #3a5be6;
    color: white;
}

.el-loading-spinner .el-loading-text {
    text-align: justify;
    margin-left: -25px !important;
}

.spanStyle {
    white-space: nowrap; /*强制span不换行*/
    display: inline-block; /*将span当做块级元素对待*/
    line-height: 0.9; /*数字与之前的文字对齐*/
}

.circular {
    width: 56px;
    height: 56px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    animation: spinner-e04l1k 1s infinite linear;
}

.circular::before,
.circular::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
}

.circular::before {
    border-color: #474bff #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
}

.circular::after {
    margin: 8.9px;
}

@keyframes spinner-e04l1k {
    100% {
        transform: rotate(1turn);
    }
}

.bgWidth {
    min-width: 1389px;
}

.getLeft {
    width: 27%;
}

.getRight {
    width: 73%;
}

.getTo {
    margin-top: 15%;
    float: right;
    margin-right: 5%;
}

.getOk {
    width: 68%;
    height: 30%;
    float: right;
    margin-top: 10%;
    margin-right: 4%;
}

.getBox {
    margin-left: -16px;
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
}

.getPArent {
    margin-top: -10px;
    position: relative;
}

.getInput {
    font-family: monospace;
    max-width: 40px;
    outline: none;
    border: 1px solid #dadada;
    padding: 5px;
    text-align: center;
    font-weight: 600;
    border-radius: 5px;
    background-color: #f3f7fe;
    transition: .3s;
    color: #3b82f6;
}

.getInput:focus {
    border: 1px solid #3b82f6;
    box-shadow: 0 0 0 4px #3b83f65f
}


.limitMultiLine {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.limitMultiLins {
    color: blue;
    /*width: 60px;*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;

    /*-webkit-line-clamp: 1;*/
}

.buttonPadding {
    width: 70px;
    height: 25px;
    font-size: 15px;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    color: #000000;
    /*font-family: 'Times New Roman', Times, serif;*/
    font-weight: 700;
    position: relative;
    transition: all 0.5s;
    z-index: 1;
}

.buttonPadding::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: #0c27eb;
    z-index: -1;
    transition: all 0.5s;
}

.buttonPa {
    background-color: #0c27eb;
    color: white;

}

.buttonPadding:hover::before {
    width: 100%;
}

.buttonPadding:hover {
    color: white;
}

.buttonPadding:active:before {
    background: #b9b9b9;
}

.el-icon-picture-outline-round {
    font-size: 34px;
    color: #dad8d8;
}

.el-image {
    border-radius: 0.3rem;
}

.getNum .el-input-number__increase {
    width: 40px !important;
}

.getNum .el-input-number {
    margin-left: 13px;
}

.widSco {
    height: 250px;
    width: 93%;
    overflow-y: auto;
}

.widSon {
    min-width: 220px;
    width: 30%;
    border-radius: 0.5rem;
}

.getwidSon {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    border: 1px solid #4c5de5;
}

.widSon:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    border: 1px solid #4c5de5;
}

.index1 {
    border-radius: 0.3rem;
    position: relative;
    width: 100%;
    height: 100%
}

.index1 .el-image {
    width: 100px;
    height: 100px;
    border-radius: 0.3rem;
}

.index2 {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    margin-top: -100px
}

.inputW .el-input {
    width: 50px !important;
}

.positionPa {
    width: 98%;
    min-width: 1000px;
    position: relative;
}

.positionSon {
    align-items: center;
    display: flex;
    margin-left: 192px;
    margin-top: 12px;
    z-index: 2;
    position: absolute;
}

.positionSon .el-input {
    width: 93px !important;
}

.positionSon .el-input__inner {
    height: 28px;
}

.positionSon .el-input__icon {
    line-height: 0px !important;
}

.inputW {
    color: #606266;
}

.inputW .el-input__inner {
    text-align: center;
    height: 25px !important;
}

.labelTy {
    color: white;
    font-size: 12px !important;
    margin-top: 48px;
    width: 100%;
    height: 52px;
    bottom: 0;
    border-radius: 0 0 0.3rem 0.3rem;
    background-color: rgb(73 73 73 / 50%);
}

.wSool {
    height: 666px;
    overflow-y: auto;
}

/*移动时间*/
.wFixed {
    height: 100px;
    position: relative;
    width: 90%;
}

.marginGet {

    width: 100%;
    height: 100%;
    border-radius: 0.3rem;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    position: absolute;
    margin-top: -100px;
    transition: all 0.5s;
}

.getposition {
    height: 110px;
    position: absolute;
    transition: all 1s linear;
}

.wGli {
    position: relative;
    background: white;
    min-width: 100px;
    height: 100px;
    border-radius: 0.3rem;
}

.getFault .el-button--default {
    padding: 4px 4px !important;
}

.getFault .el-button--default:hover {
    padding: 4px 4px !important;
}

.getCorl {
    border-radius: 50%;
    background-color: #e1e1e1;
}

.getCorl:hover i {
    transition: all 0.5s ease;
    color: white;
}

.getCorl i {
    color: black;
}

.bgW {
    font-size: 14px !important;
    width: 100%;
    background-color: #f5f6f8;
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    background-color: #b5b1b1;
}

::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px #A9A9A94C;*/
    /*border-radius: 10px;*/
    background-color: #ffffff;

}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /*-webkit-box-shadow: inset 0 0 6px #A9A9A94C;*/
    background-color: #e1e1e1;
}

.el-input__inner, .el-table .el-input__inner {
    padding: 0 8px !important;
    line-height: 28px
}

.witNumber .el-input-number {
    width: 55px !important;
}

/*.witNumber .el-input-number .el-input__inner{*/
/*    padding: 0!important;*/
/*}*/
.witNumber .el-input-number .el-input__inner {
    text-align: left;
}

.h-title {
    height: 48px;
    background-color: #f5f6f8;
    display: flex;
    color: #909399;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
}

.witNumber .el-input-number__decrease {
    width: 20px !important;
}

.witNumber .el-input-number__increase {
    width: 20px !important;
}

.el-table .cell {
    padding: 0 !important;
}

.el-table {
    border-right: 1px solid #f5f6f8 !important;
}

.el-table th.el-table__cell {
    background-color: #f5f6f8 !important;
}
</style>