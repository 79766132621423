<script>
import icon from '@/assets/pages/icon.png';
import kf from '@/assets/pages/kf.jpg';
import {userData} from "@/api/department";
import axios from "axios";
import PopupManager from "element-ui/lib/utils/popup/popup-manager"

import ScreenShort from "js-web-screen-shot";
// import html2canvas from "html2canvas";
import {checkAuditTime, compare, timeFormat} from "@/utils/antiShake";
// import VueDragResize from 'vue-drag-resize';
const ws = new WebSocket("wss://chat.niuniucrm.com/wss/")
PopupManager.zIndex=100001

export default {
    name: 'gotHome',
    data() {
        return {
            contentList:[],
            userlist: [],
            msg: "",
            imagese: "",
            imageseList: [],
            kf: kf,
            icon: icon,
            got: 777,
            isCollapse: false,
            pathName: [
                {
                    name: '首页',
                    path: '1',
                    pathname: '/homePage'
                },
                {
                    name: '个人中心',
                    path: '2',
                    pathname: '/personalProfile',
                    children: [
                        {
                            name: '个人档案',
                            path: '/personalProfile'
                        },
                    ]
                },
                {
                    name: '帮助',
                    path: '7',
                    pathname: "/GETpROM",
                },
                {
                    name: '分销采购',
                    path: '3',
                    pathname: '/orderComponent',
                    children: [
                        {
                            name: '分销订单管理',
                            path: '/orderComponent'
                        },
                        {
                            name: '采购退款申请',
                            path: '/RefundRequest'
                        },
                        {
                            name: '利润报表查询',
                            path: '/ProfitReport'
                        },
                        {
                            name: '退货地址管理',
                            path: '/addressVue',
                        },
                    ]
                },
                {
                    name: '店铺管理',
                    path: '4',
                    pathname: '/PurchaseAccount',
                    children: [
                        {
                            name: '采购账号管理',
                            path: '/PurchaseAccount'
                        },
                        {
                            path: '/DistributionGoods',
                            name: '分销商品管理',
                        },
                        {
                            name: '下单参数设置',
                            path: '/SetOrderingParameter',
                        },
                    ]
                },
                {
                    name: '订单记录查询',
                    pathname: '/OrderRecordQ',
                },
            ],
            arrImage: [],
            imageShow: false,
            pathNameOne: '首页',
            pathNameTwo: '',
            getPath: '/',
            activeNames: ['2', '3'],
            token: '',
            userData: {
                shop_id: '',
                createtime: '',
                expire_time: '',
                shop_name: '',
                auth_code: '',
                notice: '',
                serverimg: ''

            },
            uploadRequestUrl: 'https://chat.niuniucrm.com/api/upload/uploadfile',
            pathGet: '/',
            wWidth: 0,
            srcdiv: null,
            getVisible: false,
            getPUblicMessage: {},
            getAvtion: false,
            timer: new Date().getTime(),
            getOnline: false,
        }
    },

    watch: {
        wWidth: {
            handler(val) {
                if (val < 1669) {
                    this.isCollapse = true
                }
            }
        },
        $route: {
            handler(newValue) {
                if (newValue) {
                    const indexPath = []
                    for (let i = 0; i < this.pathName.length; i++) {
                        if (this.pathName[i].pathname === newValue.fullPath) {
                            indexPath[0] = this.pathName[i].path
                            indexPath[1] = newValue.fullPath
                        } else {
                            if (this.pathName[i].children) {
                                for (let j = 0; j < this.pathName[i].children.length; j++) {
                                    if (this.pathName[i].children[j].path === newValue.fullPath) {
                                        indexPath[0] = this.pathName[i].path
                                        indexPath[1] = newValue.fullPath
                                    }
                                }
                            }

                        }
                    }
                    this.selectPath(newValue.fullPath, indexPath)
                }

            }, deep: true, immediate: true
        }
    },
    mounted() {
        window.addEventListener("resize", this.watchWindowSize);
        const pathName = JSON.parse(localStorage.getItem('pathName'))
        if (pathName) {
            this.pathNameOne = pathName.pathNameOne
            this.pathNameTwo = pathName.pathNameTwo,
                this.getPath = pathName.getPath
        }
        this.init()
        // this.messagePerson()
        const _this = this;
        this.timer = setInterval(function () {
            _this.date = new Date();
            _this.getOnline = checkAuditTime("9:00", "18:00")
        }, 1000);
        window.previewNvrVideo = function (channelNum) {
            _this.getText(channelNum)
        }
    },


    methods: {
        getMessage() {
            const data = {
                shop_id: this.userData.shop_id,
                platform_type: 2,
            }
            axios.post('https://chat.niuniucrm.com/websocket/index/getMsgLog', data, {
                'Content-type': 'multipart/form-data'
            }).then((res) => {
                if (res.data.code === 1) {
                    let datas = res.data.data.map(item => {
                        item.icon = icon;
                        return item;
                    });
                    for (let i = 0; i <= datas.length; i++) {
                        //头像
                        if (datas[i].send_type == 1) {
                            datas[i].icon = datas[i].send_type == 1 ? this.userData.head_image : icon
                        }
                        //遍历数据
                        if (datas[i]) {
                            this.userlist.push(datas[i]);
                            this.userlist.sort(compare("time"))
                            //存图片
                            if (datas[i].msg_type == 2) {
                                this.arrImage.push(datas[i].content)
                            }
                        }
                    }
                    this.userlist.push(datas);
                }
            }).catch(() => {
            })

        },

        init() {
            ws.addEventListener("message", this.handleWsMessage.bind(this), false)
            ws.addEventListener("close", this.handleWsClose.bind(this), false)
            ws.addEventListener("error", this.handleWsError.bind(this), false)
            ws.addEventListener("open", this.handleWsOpen.bind(this), false)
        },
        messagePerson() {
            userData().then(res => {
                res.data.createtime = new Date(res.data.createtime * 1000).toLocaleString()
                res.data.updatetime = new Date(res.data.updatetime * 1000).toLocaleString()
                res.data.is_vip = res.data.is_vip == 1 ? '否' : '是'
                this.code = res.data.auth_code
                res.data.serverimg = localStorage.getItem("url") + res.data.serverimg
                res.data.auth_code = res.data.auth_code.substr(0, 5) + '******' + res.data.auth_code.substr(10, 5)
                this.userData = res.data
            })
        },

        handeleSendBtnClick() {
            //112
            const msg = this.msg;
            if (!msg.trim().length && this.imagese === '') {
                return
            }
            const lastTime = new Date().getTime();
            const nextTime = this.userlist[this.userlist.length - 1].time
            let time_gap = "2"
            const gap = lastTime / 1000 - nextTime
            if (gap >= 60) {
                time_gap = "1"
            }
            const data = {
                content: this.msg === "" ? this.imagese : this.msg,
                group_id: this.getPUblicMessage.group_id,
                shop_id: this.userData.shop_id,
                msg_type: this.msg === "" ? "2" : "1",
                type: 'msg',
                platform_type: 2,
                send_type: '1',
                time_gap: time_gap,
            }
            ws.send(JSON.stringify(data))
            this.$nextTick(() => {
                const datas = {
                    content: data.content,
                    group_id: data.group_id,
                    send_type: data.send_type,
                    platform_type: 2,
                    shop_id: data.shop_id,
                    create_time: parseInt(new Date().getTime() / 1000),
                    msg_type: data.msg_type,
                    time_gap: time_gap,
                }
                axios.post('https://chat.niuniucrm.com/websocket/index/saveMsg', datas, {
                    'Content-type': 'multipart/form-data'
                }).then(() => {
                }).catch(() => {
                })
            })
            this.imagese = ''
            this.imageShow = false
            // const imageGOT = document.getElementsByClassName('images')
            // imageGOT[0].style.display = 'none';

        },
        async handleWsMessage(e) {
            const data = JSON.parse(e.data)
            if (data.type === "bind") {
                await this.messagePerson();
                setTimeout(() => {
                    const postMessage = {
                        'client_id': data.client_id,
                        'shop_id': this.userData.shop_id,
                        'send_type': 1,
                        "platform_type": 2,
                        "shop_name": this.userData.shop_name,
                        "head_image": this.userData.head_image
                    }
                    axios.post('https://chat.niuniucrm.com/websocket/index/bind', postMessage, {
                        'Content-type': 'multipart/form-data'
                    }).then((res) => {
                        if (res.data.code == 0) {
                            this.$message.error(res.data.msg)
                        } else if (res.data.code == 1) {
                            this.getMessage();
                            this.getPUblicMessage = res.data.data
                            ws.send(JSON.stringify(res.data.data))
                            setTimeout(() => {
                                document.getElementById("getscroll").scrollIntoView({
                                    block: "end",
                                    behavior: "smooth", // 代表平滑滚动
                                });
                            }, 1000);
                        }
                    }).catch(() => {
                        this.$message.warning('链接失败')
                    })

                }, 1000)
            } else if (data.type === "initMsg" && data.shop_id === this.userData.shop_id.toString()) {
                if (data.send_type === 2) {
                    this.getPUblicMessage = data
                    const dataMassge = {
                        content: data.content,
                        group_id: data.group_id,
                        platform_type: 2,
                        send_type: data.send_type,
                        shop_id: data.shop_id,
                        uniqued: data.uniqued,
                        time: data.time,
                        type: data.type,
                        username: "牛牛客户经理",
                        icon: icon,
                        time_gap: data.time_gap
                    }
                    this.userlist.push(dataMassge)
                    const condition = {
                        shop_id: this.getPUblicMessage.shop_id,
                        platform_type: 2,
                    }
                    axios.post('https://chat.niuniucrm.com/api/index/index', condition, {
                        'Content-type': 'multipart/form-data'
                    }).then((res) => {
                        const contentList = res.data.data.data
                        this.contentList = contentList
                        let getContent = `<span>常见问题</span></br>`
                        for (let i = 0; i < contentList.length; i++) {
                            getContent += `
                            <span class="hotField"
                            onclick="previewNvrVideo(${contentList[i].id})">
                            ${contentList[i].question}
                            </span>
                            <br/>`
                        }
                        const getSeconds = {
                            content: getContent,
                            group_id: this.getPUblicMessage.group_id,
                            platform_type: 2,
                            send_type: "2",
                            shop_id: this.getPUblicMessage.shop_id,
                            uniqued: contentList.uniqued,
                            time: parseInt(new Date().getTime() / 1000),
                            username: "牛牛客户经理",
                            icon: icon,
                            time_gap: "2"
                        }
                        this.userlist.push(getSeconds)
                    })
                }
            } else if (data.type === "saveMsg") {
                this.getPUblicMessage = data
                this.msg = ''
                let dataMassge = {}
                if (data.send_type == 1) {
                    dataMassge = {
                        time_gap: data.time_gap,
                        content: data.content,
                        group_id: data.group_id,
                        send_type: data.send_type,
                        shop_id: data.shop_id,
                        time: data.time,
                        platform_type: 2,
                        type: data.type,
                        username: this.userData.shop_name,
                        msg_type: data.msg_type,
                        icon: this.userData.head_image
                    }
                } else if (data.send_type == 2) {
                    dataMassge = {
                        content: data.content,
                        msg_type:data.msg_type,
                        group_id: data.group_id,
                        send_type: data.send_type,
                        shop_id: data.shop_id,
                        time: data.time,
                        type: data.type,
                        platform_type: 2,
                        time_gap: data.time_gap,
                        username: "牛牛客户经理",
                        icon: icon
                    }
                }
                this.userlist.push(dataMassge)
                if (dataMassge.msg_type === "2") this.arrImage.push(dataMassge.content)
                setTimeout(() => {
                    document.getElementById("getscroll").scrollIntoView({
                        block: "end",
                        behavior: "smooth", // 代表平滑滚动
                    });
                });

            }

        },
        saveMessage(content, send_type, time_gap) {
            const datas = {
                content: content,
                group_id: this.getPUblicMessage.group_id,
                send_type: send_type,
                platform_type: 1,
                shop_id: this.getPUblicMessage.shop_id,
                create_time: parseInt(new Date().getTime() / 1000),
                msg_type: "1",
                time_gap: time_gap,
            }
            axios.post('https://chat.niuniucrm.com/websocket/index/saveMsg', datas, {
                'Content-type': 'multipart/form-data'
            }).then(() => {
            }).catch(() => {
            })
        },
        getText(value) {
            const content = this.contentList.find(el => el.id === value).question
            const dataMassge = {
                time_gap: "1",
                content: content,
                group_id: this.getPUblicMessage.group_id,
                send_type: "1",
                shop_id: this.getPUblicMessage.shop_id,
                time: parseInt(new Date().getTime() / 1000),
                platform_type: 1,
                username: this.userData.shop_name,
                msg_type: '1',
                icon: this.userData.head_image
            }
            this.userlist.push(dataMassge)
            this.glide()
            this.saveMessage(content, "1", "1")
            setTimeout(() => {
                const question = this.contentList.find(el => el.id === value).answer
                const buisMes = {
                    content: question,
                    msg_type: "1",
                    group_id: this.getPUblicMessage.group_id,
                    send_type: "2",
                    shop_id: this.getPUblicMessage.shop_id,
                    time: parseInt(new Date().getTime() / 1000),
                    platform_type: 1,
                    time_gap: "2",
                    username: "牛牛客户经理",
                    icon: icon
                }
                this.userlist.push(buisMes)
                this.saveMessage(question, "2", "2")
                this.glide()
            }, 1000)

        },
        glide() {
            setTimeout(() => {
                document.getElementById("getscroll").scrollIntoView({
                    block: "end",
                    behavior: "smooth", // 代表平滑滚动
                });
            })
        },
        handleWsClose(e) {
            console.log("Close", e)
        },
        handleWsError(e) {
            console.log("Error", e)
        },
        handleWsOpen(e) {
            console.log("Open", e)
        },
        watchWindowSize() {
            this.wWidth = document.documentElement.clientWidth
        },
        handleOpenInt() {
            if (this.isCollapse == true) {
                // setTimeout(()=>{
                this.isCollapse = false
                // })
            } else {
                // setTimeout(()=>{
                this.isCollapse = true
                // })
            }
        },
        copyValue() {
            const that = this;
            this.$copyText(this.code).then(
                function () {
                    that.$message({
                        message: "复制成功!",
                        type: 'success'
                    });
                },
                function () {
                    that.$message({
                        message: "复制失败!",
                        type: 'error'
                    });
                }
            );
        },
        selectPath(index, indexPath) {
            this.pathNameTwo = ""
            this.pathGet = index
            if (indexPath.length == 2) {
                this.getPath = this.pathName.find(item => item.path == indexPath[0])?.pathname
                this.pathNameOne = this.pathName.find(item => item.path == indexPath[0])?.name
                const datas = this.pathName.find(item => item.path == indexPath[0])?.children
                if (datas) {
                    this.pathNameTwo = '/' + datas.find(el => el.path === indexPath[1]).name
                }

            } else if (indexPath.length == 1) {
                this.pathNameTwo = undefined
                this.pathNameOne = this.pathName.find(item => item.pathname == indexPath[0])?.name
                this.getPath = indexPath[0]
            }
            const dataPath = {pathNameOne: this.pathNameOne, pathNameTwo: this.pathNameTwo, getPath: this.getPath}
            localStorage.setItem('pathName', JSON.stringify(dataPath))
        },
        jump(val) {
            setTimeout(() => {
                if (val === 1) {
                    window.open('http://dybanjia.niuniucrm.com/index', '_blank')
                } else {
                    window.open('http://dycrm.niuniucrm.com/index', '_blank')
                }
            })
        },
        giveService() {
            window.open('https://fuwu.jinritemai.com/detail?service_id=21223&from=isv.detail', '_blank')
        },
        getJUMP() {
            this.$router.push({path: '/GETpROM'})
        },
        drag(ev, divdom) {
            this.srcdiv = divdom;
            ev.dataTransfer.setData("text/html", divdom.innerHTML);
        },
        drop(ev, divdom) {
            ev.preventDefault();
            if (this.srcdiv != divdom) {
                this.srcdiv.innerHTML = divdom.innerHTML;
                divdom.innerHTML = ev.dataTransfer.getData("text/html");
            }
        },
        allowDrop(ev) {
            ev.preventDefault();
        },
        convertImageToCanvas(image) {
            let canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            canvas.getContext('2d').drawImage(image, 0, 0)
            return canvas
        },
        callback(base64data) {
            let image = new Image();
            image.src = base64data.base64;
            let that = this
            image.onload = function () {
                let canvas = '';
                canvas = that.convertImageToCanvas(image);
                let base64 = canvas.toDataURL('image/png');
                const dlLink = document.createElement('a');
                dlLink.download = "image";
                dlLink.href = base64;
                dlLink.click();
                const url = canvas.toDataURL('image/jpeg'); // base64编码的图片
                // 通过atob将base64进行编码
                // 通过atob将base64进行编码
                const bytes = window.atob(url.split(',')[1]);
                // 处理异常，将ASCII码小于0的转换为大于0,进行二进制转换
                const buffer = new ArrayBuffer(bytes.length);
                // 生成一个8位数的数组
                const uint = new Uint8Array(buffer);
                for (let j = 0; j < bytes.length; j++) {
                    uint[j] = bytes.charCodeAt(j)// 根据长度返回相对应的Unicode 编码
                }
                // Blob对象  // type 为图片的格式
                const imageFile = new Blob([buffer], {type: 'image/jpeg'});
                let formData = new FormData()
                // 上传的参数名是file，数据是该图片的blob对象，文件名是当前时间戳.jpg；
                //如果不指定第三个参数文件名，那么默认文件名就是blob，没有后缀，后端的上传接口如果要截取文件名的话会出问题
                formData.append('image', imageFile, Date.now() + '.jpeg')
                axios.post('https://chat.niuniucrm.com/api/upload/uploadfile', formData, {
                    'Content-type': 'multipart/form-data'
                }).then((res) => {
                    that.imagese = res.data.data.src
                    that.imageShow = true

                })
            }

        },
        getClear() {
            this.imagese = ''
            this.imageShow = false
        },
        closeFn() {
            // 取消截图的回调
        },
        async GETSHOTsCENT() {
            //全屏截图
           //  this.msg = ""
           //  this.getVisible = true
           // setTimeout(()=>{
           //     html2canvas((document.getElementById("app")), {
           //         backgroundColor: null,
           //         useCORS: true,
           //         scale: 1,
           //     }).then((canvas) => {
           //         let img = new Image();
           //         img.src = canvas.toDataURL("image/jpg")
           //         this.imagese = img.src
           //         this.getVisible = false
           //         this.callbackGET(img.src);
           //
           //     })
           // })
            //编辑截图
            setTimeout(()=> {
                const screenShotHandler = new ScreenShort({  // eslint-disable-line no-unused-vars
                    // 是否启用webrtc，值为boolean类型，值为false则使用html2canvas来截图
                    enableWebRtc: false,
                    level: 2023,
                    // useCORS: true,
                    allowTaint: false,
                    // willReadFrequency:true,
                    completeCallback: this.callback, // 截图成功完成的回调
                    closeCallback: this.closeFn // 截图取消的回调
                })
            })

        },
        //下载图片
        callbackGET(base64data) {
            let image = new Image();
            image.src = base64data;
            let that = this
            image.onload = function () {
                let canvas = '';
                canvas = that.convertImageToCanvas(image);
                let base64 = canvas.toDataURL('image/png');
                const dlLink = document.createElement('a');
                dlLink.download = "image";
                dlLink.href = base64;
                dlLink.click();
                const url = canvas.toDataURL('image/jpeg'); // base64编码的图片
                // 通过atob将base64进行编码
                // 通过atob将base64进行编码
                const bytes = window.atob(url.split(',')[1]);
                // 处理异常，将ASCII码小于0的转换为大于0,进行二进制转换
                const buffer = new ArrayBuffer(bytes.length);
                // 生成一个8位数的数组
                const uint = new Uint8Array(buffer);
                for (let j = 0; j < bytes.length; j++) {
                    uint[j] = bytes.charCodeAt(j)// 根据长度返回相对应的Unicode 编码
                }
                // Blob对象  // type 为图片的格式
                const imageFile = new Blob([buffer], {type: 'image/jpeg'});
                let formData = new FormData()
                // 上传的参数名是file，数据是该图片的blob对象，文件名是当前时间戳.jpg；
                //如果不指定第三个参数文件名，那么默认文件名就是blob，没有后缀，后端的上传接口如果要截取文件名的话会出问题
                formData.append('image', imageFile, Date.now() + '.jpeg')
                axios.post('https://chat.niuniucrm.com/api/upload/uploadfile', formData, {
                    'Content-type': 'multipart/form-data'
                }).then((res) => {
                    that.imagese = res.data.data.src
                    that.imageShow = true
                    that.arrImage.push(res.data.data.src)
                    // const imageGOT = document.getElementsByClassName('images')
                    // imageGOT[0].style.display = 'block';
                })
            }

        },
        downloadImage(url) {
            // 如果是在网页中可以直接创建一个 a 标签直接下载
            let a = document.createElement('a')
            a.href = url
            a.download = '截图'
            a.click();
        },
        onDrag(e) {
            console.log(e)
        },
        handleKeyCode(e) {
            if (e.code === "Enter") {
                this.handeleSendBtnClick()
            }
        },
        getPartTime(val) {
            const data = timeFormat(val)
            return data;
        }
        ,
        getImage(e) {
            const reg = /.(png|jpg|gif|jpeg|webp)$/;
            if(reg.test(e.target.attributes[0].value)){
                this.$hevueImgPreview(e.target.attributes[0].value)
            }
        }

    }
}
</script>
<template>
    <div class="gotHome flex" id="allImage">
        <div class="nav items-center ibps-layout-header-aside-menu-side" :class="{'navRepalce':isCollapse}">
            <div class="img-w flex flex-row	items-center justify-center">
                <img :src="icon" style="width: 28px;height: 28px ;border-radius: 8px" alt="">
                <span v-show="isCollapse==false" class="inline-block align-middle ml-2  text-3xl ">牛牛分销下单</span>
            </div>
            <el-menu
                style="width: 200px"
                v-bind:class="{with1:isCollapse}"
                router
                @select="selectPath"
                :default-active="this.$route.path"
                class="el-menu-vertical-demo"
                :collapse="isCollapse">
                <el-menu-item index="/homePage">
                    <i class="icon-color">&#xe61d;</i>
                    <span slot="title">首页</span>
                </el-menu-item>
                <el-submenu index="4">
                    <template slot="title">
                        <i class="icon-color">&#xe63e;</i>
                        <span slot="title">店铺管理</span>
                    </template>
                    <el-menu-item index="/PurchaseAccount">
                        <i class="icon-color">&#xeb68;</i>
                        <span slot="title">采购账号管理</span>
                    </el-menu-item>
                    <el-menu-item index="/DistributionGoods">
                        <i class="icon-color">&#xe600;</i>
                        <span slot="title">分销商品管理</span>
                    </el-menu-item>
                    <el-menu-item index="/SetOrderingParameter">
                        <i class="icon-color">&#xe607;</i>
                        <span slot="title">下单参数设置</span>
                    </el-menu-item>
                </el-submenu>
                <el-submenu index="3">
                    <template slot="title">
                        <i class="icon-color">&#xe67d;</i>
                        <span slot="title">分销采购</span>
                    </template>
                    <el-menu-item index="/orderComponent">
                        <i class="icon-color">&#xe62b;</i>
                        <span slot="title">分销订单管理</span>
                    </el-menu-item>

                    <el-menu-item index="/RefundRequest">
                        <i class="icon-color">&#xe641;</i>
                        <span slot="title">采购退款申请</span>
                    </el-menu-item>
                    <el-menu-item index="/ProfitReport">
                        <i class="icon-color">&#xe604;</i>
                        <span slot="title">利润报表查询</span>
                    </el-menu-item>
                    <el-menu-item index="/addressVue">
                        <i class="icon-color">&#xe62a;</i>
                        <span slot="title">退货地址管理</span>
                    </el-menu-item>
                </el-submenu>
                <el-menu-item index="/OrderRecordQ" style="margin-left: -1px">
                    <i class="el-icon-s-order" style="width: 17px"></i>
                    <span slot="title">订单记录</span>
                </el-menu-item>
                <el-submenu index="2">
                    <template slot="title">
                        <i class="icon-color">&#xe663;</i>
                        <span slot="title">个人中心</span>
                    </template>
                    <el-menu-item index="/personalProfile">
                        <i class="icon-color">&#xe638;</i>
                        <span slot="title">个人档案</span>
                    </el-menu-item>
                </el-submenu>
                <el-menu-item style="margin-left:-1px" class="getBackrg" :index="pathGet"
                              @click="jump(1)">
                    <i class="el-icon-truck" style="width: 14px"></i>
                    <span slot="title">
                               商品搬家
                        </span>
                </el-menu-item>
                <el-menu-item style="margin-left:-1px" class="getBackrg" :index="pathGet"
                              @click="jump(2)">
                    <i class="el-icon-message" style="width: 14px"></i>
                    <span slot="title">
                                短信催评
                        </span>
                </el-menu-item>
            </el-menu>
            <div class="wtd-cen">
                <el-button class="text-black-700" type="text" @click="handleOpenInt">
                    <i v-if="isCollapse==false" class="text-3xl el-icon-s-fold"></i>
                    <i v-else class="text-3xl el-icon-s-unfold"></i>
                </el-button>
            </div>
        </div>
        <div class="Ribbon flex-1 flex flex-col box-border">
            <div class="bg-white box-top  h-top p-3 flex items-center justify-between">
                <div>
                    <el-breadcrumb separator="">
                        <el-breadcrumb-item :to="getPath"> {{ pathNameOne }}</el-breadcrumb-item>
                        <el-breadcrumb-item v-show="pathNameTwo !==undefined">{{ pathNameTwo }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div class="bg-content flex-1 p-6">
                <keep-alive>
                    <router-view>
                    </router-view>
                </keep-alive>
            </div>
        </div>
        <div class="right py-3 flex items-start flex-col p-3 boxs">
            <div class="my-1.5 icon-s flex items-center  border-solid border-b">
                <el-image style="width: 30px;height: 30px" :src="this.userData.head_image"></el-image>
                <span class="ml-2">{{ userData.shop_name }}</span>
            </div>
            <span class="my-1 mt-2.5">到期时间：<br/>{{ userData.expire_time }}</span>
            <el-button @click="giveService" class="my-1" type="primary">立即续费</el-button>
            <div class="w-collapse">
                <el-collapse v-model="activeNames">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <i class="header-icon el-icon-message-solid"></i>系统公告
                        </template>
                        <div class="inBox">
                            <el-timeline>
                                <el-timeline-item
                                    v-for="(activity) in userData.notice"
                                    :key="activity.createtime"
                                    :timestamp="activity.createtime" placement="top">
                                    <el-card>
                                        <h4 class="font-bold">{{ activity.title }}</h4>
                                        <p>{{ activity.content }}</p>
                                    </el-card>
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="3">
                        <template slot="title">
                            <i class="header-icon el-icon-info"></i>帮助
                        </template>
                        <div class="flex flex-col">
                            <a href="https://efpde0crhj.feishu.cn/docx/GiBDdCZZio0uxZxfRhecgY3lnCg"
                               class="text-blue-700"
                               target="_blank">
                                <i class="header-icon el-icon-s-management mx-2"></i>牛牛分销下单教程
                            </a>
                            <div @click="getJUMP" class="getTck">
                                <i class="header-icon el-icon-question"></i><span>常见问题</span>
                            </div>
                        </div>

                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <template slot="title">
                            <i class="header-icon el-icon-s-custom"></i>专属客服
                        </template>
                        <div>客服电话：{{ userData.phone }}</div>
                        <div>客服微信：{{ userData.wx_num }}</div>
                        <div class=" mt-1.5">
                            <el-image
                                style="width: 175px; height: 175px"
                                :src="userData.serverimg"
                                fit="fill">
                            </el-image>
                        </div>
                    </el-collapse-item>

                </el-collapse>
            </div>
            <!--            <vue-drag-resize-->
            <!--                :parent="false"-->
            <!--                :isActive="this.getAvtion"-->
            <!--                :resizable="false"-->
            <!--            :w="400"-->
            <!--            :h="500"-->
            <!--                :x="600"-->
            <!--                :y="300"-->
            <!--            class="risk-remind"-->
            <!--            >-->
<!--&lt;!&ndash;-->
            <div v-if="getVisible" @click="getVisible=false;getAvtion=false" class="getColor" id="batdrag"
                 @drοp="drop(event,this)"
                 @dragοver="allowDrop(event)"
                 draggable="true" @dragstart="drag(event, this)">
                <i class="el-icon-chat-dot-square"/>
            </div>
            <div v-else class="chatPO">
                <div style="padding: 8px;display: flex;justify-content: space-between;">
                    <div class="flex items-center">
                        <el-image style="width: 30px; height: 30px;border-radius: 50%" :src="icon" />
                        <span class="ml-2 font-semibold text-sm">
                            商家助手
                            <span class="text-sm">（工作9:00-18:00）
                            <span v-if="getOnline">在线
                                <div class="container">
                                    <span></span>
                                </div>
                            </span>
                            <span class="text-gray-300" v-else>离线中</span></span>
                        </span>
                    </div>

                    <div
                        class="gethover" style="display: flex;flex-direction: column;align-content: space-around;"
                        @click="getVisible = true; getAvtion = false">
                        <i class="el-icon-minus"></i>
                    </div>
                </div>
                <div class="box-border  minHight">
                    <ul id="getscroll" style="padding: 16px 18px;">
                        <li class=" my-4 "
                            :key="item.uniqued"
                            v-for="item in userlist"
                        >
                            <div v-if="item.time_gap=='1'" class=" flex justify-center   text-xs">
                                <span class="bg-gray-300 my-2 py-0.5 px-1 text-white rounded">{{
                                        getPartTime(item.time)
                                    }}</span></div>
                            <div v-bind:class="{getLeft:item.send_type==2,getRight:item.send_type==1}">
                                <!--                                <el-image style="width:25px;height: 25px;" :src="item.icon">-->
                                <!--                                </el-image>-->
                                <div class=" flex flex-col justify-start"
                                     v-bind:class="{'items-end':item.send_type==1,getText:item.send_type==2,getRightText:item.send_type==1}">
                                    <!--                                    <div>-->
                                    <!--                                        <span class="mb-1">{{ item.username }}</span>-->
                                    <!--                                    </div>-->

                                    <div v-if="item.msg_type==2">
                                        <el-image @click="(e)=>getImage(e)" style="width: 75px ;height: 75px"
                                                  :src="item.content">
                                            <!--                                            :preview-src-list="arrImage"-->
                                        </el-image>
                                    </div>
                                    <div @click="(e)=>getImage(e)"   v-html="item.content" v-else
                                         v-bind:class="{rightBubble:item.send_type==2,leftBubble:item.send_type==1}">
                                        <!--                                    {item.content}-->
                                    </div>

                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="my-1">

                    <el-popover
                        placement="top"
                        width="200"
                        trigger="hover"
                    >
                        <div>
                            <img style="width: 300px" :src="userData.serverimg" />
                            <span class="py-1 font-semibold text-blue-700">服务热线：{{ userData.phone }}</span>
                        </div>
                        <template #reference>
                            <div class=" bgHover bg-white mx-4 rounded-xl w-32 flex items-center px-2 py-0.5 cursor-pointer">
                                <i class="el-icon-s-custom">
                                    <span class="text-sm "> 专属人工服务</span>
                                </i>
                                <!--                                <el-icon>-->
                                <!--                                    <Service />-->
                                <!--                                </el-icon>-->

                            </div>
                        </template>
                    </el-popover>


                </div>
                <div class=" mx-4 my-2  py-2 getTopBOX rounded-xl bg-white  " style="padding: 0 8px;display:flex;flex-direction:column">
                    <div class="borderNoen mt-2">
                        <div class="getPersonIma " v-if="imageShow===true">
                            <el-image id="images" class="images"
                                      style="width: 75px ;height: 75px" :src="imagese">
                            </el-image>
                            <div @click="getClear" class="getSonImage">
                                <i class="el-icon-circle-close"></i>
                            </div>
                        </div>
                        <el-input v-else
                                  resize="none"
                                  @keydown.native="handleKeyCode($event)"
                                  type="textarea"
                                  :autosize="{ minRows: 3, maxRows: 3}"
                                  placeholder="请输入你想咨询的问题"
                                  v-model="msg">
                        </el-input>
                    </div>
                    <div class=" bottomH flex justify-between items-center mb-1">
                        <el-tooltip
                            content="截图"
                            raw-content
                        >
                            <div class="iconSize" @click="GETSHOTsCENT">

                                <svg t="1688450705051" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="3542" width="200" height="200">
                                    <path
                                        d="M779.7 588.6c-23.2 0-45.3 4.4-65.7 12.5L282.9 108.3c-10.2-11.6-27.9-12.8-39.5-2.6-11.6 10.2-12.8 27.9-2.6 39.5l424.4 485.1c-39.3 32.8-64.3 82.2-64.3 137.2 0 98.6 80.2 178.8 178.8 178.8 98.6 0 178.8-80.2 178.8-178.8s-80.2-178.9-178.8-178.9z m0 301.7c-67.7 0-122.8-55.1-122.8-122.8 0-67.7 55.1-122.8 122.8-122.8 67.7 0 122.8 55.1 122.8 122.8 0 67.7-55.1 122.8-122.8 122.8z"
                                        fill="" p-id="3543"></path>
                                    <path
                                        d="M779.7 105.6c-11.6-10.2-29.3-9-39.5 2.6L309 601.1c-20.3-8.1-42.5-12.5-65.7-12.5-98.6 0-178.8 80.2-178.8 178.8s80.2 178.8 178.8 178.8c98.6 0 178.8-80.2 178.8-178.8 0-55.1-25-104.4-64.3-137.2l424.4-485.1c10.3-11.6 9.1-29.3-2.5-39.5zM243.4 890.3c-67.7 0-122.8-55.1-122.8-122.8 0-67.7 55.1-122.8 122.8-122.8 67.7 0 122.8 55.1 122.8 122.8 0 67.7-55.1 122.8-122.8 122.8z"
                                        fill="" p-id="3544"></path>
                                </svg>

                            </div>
                        </el-tooltip>
                        <div class="getSUb  p-1 flex justify-center items-center" v-if="msg!==''||imagese!=='' "
                             @click="handeleSendBtnClick">
                            <div class="flex justify-center items-center text-white"
                                 style="width:30px;height:30px;background-color: #01b601;border-radius: 50%;">
                                <i class="el-icon-s-promotion"></i>
                                <!--                            <el-icon size="20" color="#fff">-->
                                <!--                                <Promotion/>-->
                                <!--                            </el-icon>-->
                            </div>
                        </div>

                    </div>
                    <!--                    <div class="borderNoen ">-->
                    <!--                        <div class="getPersonIma" v-if="imagese!==''">-->
                    <!--                            <el-image  id="images" class="images"-->
                    <!--                                      style="width: 75px ;height: 75px" :src="imagese">-->
                    <!--                            </el-image>-->
                    <!--                            <div @click="imagese=''" class="getSonImage">-->
                    <!--                                <i class="el-icon-circle-close"></i>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                        <el-input v-else-->
                    <!--                            @keydown.native="handleKeyCode($event)"-->
                    <!--                            type="textarea"-->
                    <!--                            :autosize="{ minRows: 4, maxRows: 4}"-->
                    <!--                            placeholder=""-->
                    <!--                            v-model="msg">-->
                    <!--                        </el-input>-->
                    <!--                    </div>-->


                </div>

            </div>
        </div>
    </div>
</template>
<style scoped>
.bgHover{
    color: #3949fa;

}
.bgHover:hover{
    color: rgb(107 114 128);
}
.bottomH {
    height: 32px;
}

::v-deep .hotField {
    color: #3949fa;
}
.container {
    position: absolute;
    top: 4.5%;
    left: 118%;
    display: flex;
    gap: 0.625em;
    transform: translate(-50%, -50%);
}

.container span {
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

.container span::before {
    content: "";
    display: block;
    border-radius: inherit;
    height: inherit;
    width: inherit;
    background-color: inherit;
    animation: ripple 1.8s ease-out infinite;
    animation-delay: inherit;
    z-index: -1;
}

.container span:nth-of-type(1) {
    background-color: #5ad176;
    animation-delay: 0.2s;
}


@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0);
    }

    to {
        opacity: 0;
        transform: scale(3);
    }
}
.getPersonIma {
    padding-top: 10px;
    width: 110px;
    height: 100%;
    position: relative;
}



.getSonImage {
    color: black;
    /*margin-left: 10px;*/
    top: 0;
    right: 20px;
    position: absolute;
}

.vdr-stick {
    display: none !important;
}

.vdr {
    border: none;
}

.minHight ul {
    /*height: 100%;*/
    overflow: auto;
    zoom: 1;
    /*min-height:250px;*/
}

.buttonSTy {
    width: 50px;
    height: 20px;
    float: right;
}
.gethover:hover {
    color:white;
    background-color: var(--hover-color-3, rgba(46, 50, 56, 0.08));
}
.gethover i{
    color:#757a7e;
}
.gethover {
    border-radius: 3px;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 4px;
    position: relative;
    cursor: pointer;
}
.borderNoen {
    height:80px;
    overflow-y: auto;
}

::v-deep .borderNoen .el-textarea__inner {
    border: none;
    resize: none;
    padding: 0;
}

/*.iconSize:first-child {*/
/*    cursor: not-allowed;*/
/*}*/

.iconSize .icon {
    width: 20px;
    margin-left: 5px;
    margin-top: 5px;
    height: 20px;
    font-size: 17px;
}

.minHight {
    background-color: #f3f3f4;
    height: 400px;
    overflow-y: auto;
}

.getLeft .el-image {
    margin-right: 8px;
}

.getRight .el-image {
    margin-right: 0;
    margin-left: 8px;
}

 .minHight::-webkit-scrollbar-track {
    background-color: #f3f3f4;
}

::v-deep .getText .el-textarea__inner {
    background-color:#fff;
    resize: none !important;
    border: none !important;
    color: black;
}

::v-deep .getRightText .el-textarea__inner {
    background-color: #fff;
    resize: none !important;
    border: none !important;
    color: black;
}

.rightBubble {
    padding: 7px;
    max-width: 350px;
    position: relative;
    word-break: break-word;
    background:#fff;
    border-radius: 5px;
}

.rightBubble:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: -11px;
    left: -1px;
    border: solid 11px;
    border-color: transparent transparent transparent #fff;
    font-size: 0;
    transform: rotate(314deg);
}

.leftBubble {
    padding: 7px;
    max-width: 350px;
    word-break: break-word;
    /*height: 35px;*/
    position: relative;
    background:#5fdf65 ;
    border-radius: 5px;
}

.leftBubble:after {
    width: 0px;
    height: 0px;
    position: absolute;
    top: 0px;
    right: -6px;
    padding: 0;
    border: solid 11px;
    border-color: transparent transparent transparent #5fdf65;
    transform: rotate(89deg);
    content: '';
}

.getLeft {
    width: 100%;
    display: flex;
    /*float: left;*/
}

.getRight {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}

/*.chatPO .el-icon-minus {*/
/*    color: white;*/
/*}*/

.risk-remind {
    /*bottom: 205px;*/
    /*right: 80px;*/
    z-index: 999999;
}

.chatPO {
    resize: none;
    background-image: linear-gradient(90deg, #ffffff 3.41%, #fff 100%);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 6px 0 12px -5px rgba(0, 0, 0, 0.12), -6px 0 12px -5px rgba(0, 0, 0, 0.12);
    filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.12));
    background-size: 100% 52px;
    background-repeat: no-repeat;
    background-color: #f3f3f4;
    overflow: hidden;
    z-index: 10000;
    position: fixed;
    width: 400px;
    height: 500px;
    right: 6%;
    bottom: 6%
}

.getColor {
    bottom: 22%;
    right: 0;
    position: fixed;
    background-color: #1f8ceb;
    color: white;
    padding: 3px;
    width: 60px;
    height: 60px;
    text-align: center;
    font-size: 37px;
    border-radius: 50%;
}

/*.getColor i {*/
/*    width: 37PX;*/
/*}*/

.getTck i {

    margin: 8px;
}

.getTck {
    cursor: pointer;
}

.getTck span {
    color: #3a5be6;
}

.el-collapse-item__content {
    padding: 0 !important;
}

.inBox {
    width: 184px;
    max-height: 200px;
    padding: 0 10px;
    box-sizing: content-box;
    overflow: auto;
}

.inBox:hover {
    /*overflow-y: auto;*/
    display: block;
}

.inBox::-webkit-scrollbar {
    display: none;
}

.getBackrg {
    /*border-top: 2px solid white;*/
    background-color: #09132a !important;
}

.getBackrg:hover {
    background-color: #3948f9 !important;
}

.el-menu {
    scrollbar-color: #dfdfe0 #091329;
    scrollbar-width: thin;
}

.el-menu::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #b5b1b1;
}

.el-menu::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px #A9A9A94C;*/
    /*border-radius: 10px;*/
    background-color: #091329;

}

.el-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    scrollbar-width: thin;
    /*-webkit-box-shadow: inset 0 0 6px #A9A9A94C;*/
    /*background-color: #e1e1e1;*/
}

.right {
    overflow: auto;
}

::v-deep .with1 {
    width: 50px !important;
}

.el-aside::-webkit-scrollbar {
    display: none;
}

::v-deep .el-collapse-item__content {
    font-size: 15px !important;
    color: #646464 !important;
}

.header-icon {
    color: #3a5be6;
}

::v-deep .el-collapse {
    border: none !important;
}

.w-collapse {
    width: 200px;
}

::v-deep .el-collapse-item__wrap {
    border: none !important;
}

::v-deep .el-collapse-item__header {
    font-weight: 700;
    font-size: 15px;
    color: #3a5be6 !important;
    border: none !important;
}

.box-top {
    box-shadow: 0 10px 8px -5px #ccc;
}

.bg-content {
    overflow: auto;
    /*background-color: #f1f4f6;*/
}

.h-top {
    width: 100%;
    top: 0;
    z-index: 99;
}

.el-breadcrumb {
    font-size: 18px;
}

::v-deep .el-breadcrumb__separator {
    margin: 0 !important;
}

.boxs {
    background-color: white;
    width: 225px;
    min-width: 225px;
    box-sizing: border-box;
}

.icon-s > img {
    width: 30px;
    height: 30px;
}

.icon-s {
    padding: 10px 0;
    width: 100%;
}

.displaySpan {
    display: none;
}

.menu-w {
    /*height: 800px;*/
}

.img-w > img {

    width: 30px;
    height: 30px;
}

.img-w {
    box-sizing: border-box;
    padding: 30px 4.5px;
    width: 100%;
}

.nav {
    flex-shrink: 0;
    transition: all 0.3s ease-in-out;
    padding: 2px;
    height: 100%;
    box-sizing: border-box;
    background: #091328;
    display: flex;
    width: 204px;
    overflow: auto;
    flex-direction: column;
}

.navRepalce {
    transition: all 0.3s ease-in-out;
    padding: 2px;
    height: 100%;
    box-sizing: border-box;
    background: #091328;
    display: flex;
    width: 60px;
    overflow: auto;
    flex-direction: column;
}

.small-w-3 {
    min-width: 350px;
}

::v-deep .el-menu-item {
    color: white;
    font-size: 15px;
}

.el-menu-item.is-active {
    color: white;
    font-size: 15px;
    background-color: #3949fa;
}

.el-menu-item:hover {
    color: white;
    font-size: 15px;
    background-color: #3949fa;
}

.icon-color {
    font-style: normal;
    color: white;
    font-weight: bolder;
}

.el-submenu__title i {
    margin-right: 5px;
    color: white !important;
}

::v-deep .el-submenu__title {
    color: white !important;
    font-size: 15px;
    font-weight: bolder;
}

::v-deep .el-submenu__title:hover {
    color: white !important;
    background-color: #091328;
}

.el-menu-item i {

    color: white !important;
}

.wtd-cen .el-button {
    padding: 0 !important;
}

/*.wtd-cen{*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*}*/
.wtd-cen .el-button--text {
    color: white;
}

::v-deep .el-menu {
    border: none;
    --collapse-transition: none;
    background-color: #091328 !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    /*max-height: 800px;*/
}

::v-deep .el-menu {
    flex: 1;
    width: 200px;
    /*transition:  all 0.3s ease;*/
    /*overflow-y: auto;*/
    /*overflow-x: hidden;*/
    max-height: 800px;
}

.navigation_bar img {
    width: 30px;
}

.navigation_bar {
    border-bottom: 2px solid #0c27eb;
}

.space-x-4 {
    box-sizing: border-box;
    padding: 10px 0 10px 20px;
}

.inline-block {
    color: white;
    font-weight: 700;
    font-size: 18px;
}

.navigation_bar {
    color: white;
    width: 100%;
    background-color: #313743;
}

.content_flex {
    background-color: #f1f4f6;
    display: flex;
    overflow: auto;
    flex: 1;
}

.Ribbon {
    background-color: #f1f4f6;

    /*min-width: 900px;*/
    height: 100%;

    box-sizing: border-box;

}

.my_menu {
    flex-shrink: 0;
    height: 100%;
    overflow: auto;
    /*transition-timing-function: linear;*/
    /*transition: width .3s linear ;*/
    background-color: #091328;
}
.getTopBOX {
    border-top: 1px solid var(--border-color-1, rgba(28, 31, 35, 0.08));
}
.gotHome {
    overflow: auto;
    height: 100%;
}
</style>
