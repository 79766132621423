<script>

import {getOrderList, getOrderStatusNum} from "@/api/department";
import nopic from "@/assets/pages/nopic.png";

export default {
    name: 'OrderRecordQ',
    data() {
        return {
            nopic: nopic,
            currentPge: 1,
            tabPane: [
                {
                    id: 1,
                    name: '全部记录',
                    bagVisible: false,
                    orderlist: [],
                    valueNum: 10,
                    currenpage: 1,
                    total: 0,
                    limite: 10,
                    postSelect: {
                        TradeName: '',
                        OrderNumber: '',
                        CreationTime: 3,
                    },
                },
                {
                    id: 2,
                    name: '待发货',
                    bagVisible: false,
                    orderlist: [],
                    valueNum: 10,
                    currenpage: 1,
                    total: 0,
                    limite: 10,
                    postSelect: {
                        TradeName: '',
                        OrderNumber: '',
                        CreationTime: 3,
                    },
                },
                {
                    id: 3,
                    name: '已发货',
                    bagVisible: false,
                    orderlist: [],
                    valueNum: 10,
                    currenpage: 1,
                    total: 0,
                    limite: 10,
                    postSelect: {
                        TradeName: '',
                        OrderNumber: '',
                        CreationTime: 3,
                    },
                },
                {
                    id: 4,
                    name: '交易关闭',
                    bagVisible: false,
                    orderlist: [],
                    valueNum: 10,
                    currenpage: 1,
                    total: 0,
                    limite: 10,
                    postSelect: {
                        TradeName: '',
                        OrderNumber: '',
                        CreationTime: 3,
                    },
                },
                {
                    id: 5,
                    name: '交易完成',
                    bagVisible: false,
                    orderlist: [],
                    valueNum: 10,
                    currenpage: 1,
                    total: 0,
                    limite: 10,
                    postSelect: {
                        TradeName: '',
                        OrderNumber: '',
                        CreationTime: 3,
                    },
                },
            ],
            activeName: '全部记录',
            bye_time: [
                {
                    name: '今天',
                    value: 1
                }, {
                    name: '昨天',
                    value: 2
                }, {
                    name: '近7天',
                    value: 3
                }, {
                    name: '近30天',
                    value: 4
                },
            ],
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        postList(val) {
            if (val == 1) {
                this.getList()
            } else {
                this.handleClick(0, val)
            }
        },
        copyValue(id) {
            const that = this;
            this.$copyText(id).then(
                function () {
                    that.$message({
                        message: "复制成功!",
                        type: 'success'
                    });
                },
                function () {
                    that.$message({
                        message: "复制失败!",
                        type: 'error'
                    });
                }
            );
        },
        getList() {
            const id = 1
            const index = this.tabPane.findIndex(el => el.id == id)
            let data = {}
            if (this.tabPane[index].postSelect.CreationTime === null) {
                data = {
                    order_id: this.tabPane[index].postSelect.OrderNumber,
                    begin_time: "",
                    end_time: '',
                    name: this.tabPane[index].postSelect.TradeName,
                    page: this.tabPane[index].currenpage,
                    limit: this.tabPane[index].limite,
                }
            } else {
                data = {
                    order_id: this.tabPane[index].postSelect.OrderNumber,
                    begin_time: this.tabPane[index].postSelect.CreationTime[0]?.getTime(),
                    end_time: this.tabPane[index].postSelect.CreationTime[1]?.getTime(),
                    name: this.tabPane[index].postSelect.TradeName,
                    page: this.tabPane[index].currenpage,
                    limit: this.tabPane[index].limite,
                }
            }
            getOrderStatusNum().then(res => {
                const data = res.data
                for (let i = 0; i < data.length; i++) {
                    const index = this.tabPane.findIndex(el => el.id == data[i].id)
                    this.tabPane[index].valueNum = data[i].total
                }

            })
            getOrderList(data).then(res => {
                this.tabPane[index].total = res.data.total
                this.tabPane[index].valueNum = res.data.total
                this.tabPane[index].orderlist = res.data.data.map(item => {
                    item.pay_time = new Date(item.pay_time * 1000).toLocaleString()
                    return item
                })
            })

        },
        handleClick(tab, el) {
            let indexPane = 1
            if (tab == 0) {
                indexPane = el
            } else {
                indexPane = this.tabPane.find(el => el.name == tab.name)?.id
            }
            const index = this.tabPane.findIndex(el => el.id == indexPane)
            const data = {
                order_id: this.tabPane[index].postSelect.OrderNumber,
                time_type: this.tabPane[index].postSelect.CreationTime,
                name: this.tabPane[index].postSelect.TradeName,
                order_status: index,
                page: this.tabPane[index].currenpage,
                limit: this.tabPane[index].limite,
            }
            getOrderList(data).then(res => {
                if (res.code === 1) {
                    this.tabPane[index].total = res.data.total
                    this.tabPane[index].orderlist = res.data.data.map(item => {
                        item.pay_time = new Date(item.pay_time * 1000).toLocaleString()
                        return item
                    })
                } else {
                    this.$notify({
                        type: 'error',
                        message: res.msg
                    })
                }

            })
        },
        handleSizeChange(el, val) {
            const index = this.tabPane.findIndex(item => item.id == el)
            this.tabPane[index].limite = val
            if (el == 1) {
                this.getList()
            } else {
                this.handleClick(0, el)
            }
        },
        handleCurrentChange(el, val) {
            const index = this.tabPane.findIndex(item => item.id == el)
            this.tabPane[index].currenpage = val
            if (el == 1) {
                this.getList()
            } else {
                this.handleClick(0, el)
            }

        },
        getResh(val, num) {
            this.handleClick(val, num)
        }
    }
}
</script>
<template>
    <div>
        <div class="bg-white rounded shadow ">
<!--            <div class="mx-8 pt-6">-->
<!--                                <el-button type="primary">同步订单</el-button>-->
<!--                                <el-button>更多操作</el-button>-->
<!--            </div>-->
            <div class="mx-8 pt-6 gettabSt">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane :key="item.name" :name="item.name" v-for="item in tabPane">
                            <span slot="label">
                                {{ item.name }}({{ item.valueNum }})
                            </span>
                        <el-form :inline="true" ref="form" :model="item.postSelect">
                            <el-form-item label="商品名称:">
                                <el-input v-model="item.postSelect.TradeName"
                                          placeholder="请输入商品名称或ID"></el-input>
                            </el-form-item>
                            <el-form-item label="订单编号:">
                                <el-input v-model="item.postSelect.OrderNumber" placeholder="请输入订单编号"></el-input>
                            </el-form-item>
                            <el-form-item label="创建时间:">
                                <el-date-picker
                                    v-model="item.postSelect.CreationTime"
                                    type="datetimerange"
                                    :picker-options="pickerOptions"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    align="right">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="postList(item.id)">查询</el-button>
                            </el-form-item>
                        </el-form>
                        <div class="getHight">
                            <el-empty v-if="item.orderlist.length===0"></el-empty>
                            <el-card class=" enbox-w my-3 mx-2 " :key="itemE.order_id"
                                     v-for="itemE in item.orderlist">
                                <div slot="header" class="clearfix   p-3 text-sm">
                                    <span>订单号:</span>
                                    <span class="text-blue-600 ">{{ itemE.order_id }}</span>
                                    <el-button type="text" @click="copyValue(itemE.order_id)" size="mini">
                                        <i class="el-icon-document-copy"></i>
                                        复制
                                    </el-button>&nbsp;
                                    <span class="">{{ itemE.pay_amount }}元</span>&nbsp;
                                    <span>支付时间：{{ itemE.pay_time }}</span>
                                    <el-button @click="getResh(0,item.id)" style="float: right; padding: 3px 0"
                                               type="text">刷新
                                    </el-button>
                                </div>
                                <div class="flex justify-between  text-gray-500 p-3" v-for="items in itemE.order_son"
                                     :key="items.order_id">
                                    <div class="flex justify-start">
                                        <div class="flex-col">
                                            <el-image
                                                class="rounded-md posiget"
                                                style="width: 70px; height: 70px "
                                                :src="items.product_pic"
                                                fit="fill">
                                                <div slot="error" class="image-slot">
                                                    <i class="el-icon-picture-outline"></i>
                                                </div>
                                            </el-image>
                                            <div v-if="items.order_id==1"
                                                 class="bg-red-400 getPOsi flex justify-around text-white">未付款
                                            </div>
                                        </div>

                                        <div></div>
                                        <div class=" flex flex-col mx-2">
                                            <span class="text-blue-600">{{ items.product_name }}</span>
                                            <span>ID:{{ items.order_id }}</span>
                                            <div class="flex">
                                                {{  items.order_spec.value }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-col">
                                        <span>金额：{{ items.pay_amount }}元</span>
                                        <span>x{{ items.item_num }}件</span>
                                    </div>
                                </div>
                                <div class="flex border-t-gray-100 text-sm bg-gray-50 p-3  border-t-2">
                                    <span>{{ itemE.order_detailed.mask_post_receiver }}</span>
                                    <span>({{ itemE.order_detailed.mask_post_tel }})</span>&nbsp;
                                    <span>{{ itemE.order_detailed.province_name }}</span>&nbsp;
                                    <span>{{ itemE.order_detailed.city_name }}</span>&nbsp;
                                    <span>{{ itemE.order_detailed.town_name }}</span>&nbsp;
                                    <span>({{ itemE.order_detailed.mask_address }})</span>
                                </div>

                            </el-card>
                        </div>
                        <div class="m-2" v-if="item.orderlist.length!==0">
                            <el-pagination
                                v-if="item.orderlist.length!==0"
                                class="flex justify-center"
                                @size-change="(val)=>handleSizeChange(item.id,val)"
                                @current-change="(val)=>handleCurrentChange(item.id,val)"
                                :current-page="item.currenpage"
                                :page-sizes="[10, 20, 30, 40]"
                                :page-size="100"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="item.total">
                            </el-pagination>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<style>
.getHight {
    height: 670px;
    overflow-y: auto;
}

.el-icon-picture-outline {
    font-size: 53px;
    color: #909399;
}

.posiget {
    position: relative;
}

.enbox-w .el-card__body {
    font-size: 14px !important;
    padding: 0px !important;
}

.getPOsi {
    margin-top: -20px;
    width: 80px;
    margin-left: -5px;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
}

.gettabSt .el-badge__content.is-fixed {
    top: 9px;
    right: 4px;
}

.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.enbox-w .clearfix {
    box-sizing: border-box;
    border-bottom: 1px solid #ebeef5;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.enbox-w .el-card__header {
    padding: 0 !important;
}

.enbox-w {
    /*border-radius: 20px!important;*/
    /*width: 95% !important;*/
    min-width: 800px;
}
</style>