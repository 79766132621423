<script>
import {userData} from "@/api/department";

export default {
    name: 'PersonalProfile',
    data() {
        return {
            userData: {},
            code: ''
        }
    },
    mounted() {
        userData().then(res => {
            res.data.createtime = new Date(res.data.createtime * 1000).toLocaleString()
            res.data.updatetime = new Date(res.data.updatetime * 1000).toLocaleString()
            res.data.is_vip = res.data.is_vip == 1 ? '否' : '是'
            this.userData = res.data
            this.code = res.data.auth_code
            res.data.auth_code = res.data.auth_code.substr(0, 5) + '******' + res.data.auth_code.substr(10, 5)
            localStorage.setItem('code', res.data.auth_code)
        })
    },
    methods: {
        copyValue() {
            const that = this;
            this.$copyText(this.code).then(
                function () {
                    that.$message({
                        message: "复制成功!",
                        type: 'success'
                    });
                },
                function () {
                    that.$message({
                        message: "复制失败!",
                        type: 'error'
                    });
                }
            );
        }

    }
}
</script>
<template>
    <div>
        <div class="small-w">
            hi!{{ userData.shop_name }}您好
        </div>
        <div class="bg-white rounded shadow  small-w">
            <div class="mx-8 p-6 box-border">
                <span class="font-bold" style="color: #303133">店铺授权码</span>
                <div class="bg-blue-50 p-3 my-3 rounded-md ">
                    <i class="el-icon-warning-outline font-bold" style="color: #0c27eb"></i>
                    <span class="mx-2" style="color: #606266">当前店铺授权码：{{ userData.auth_code }}</span>
                    <el-button type="text" @click="copyValue" size="mini">
                        <i class="el-icon-document-copy"></i>
                        复制
                    </el-button>
                </div>

            </div>
        </div>
        <div class="bg-white rounded shadow my-6 small-w getCursor">
            <div class="mx-8 p-6 box-border ">
                <el-descriptions title="个人信息">

                    <el-descriptions-item label="用户ID">{{ userData.shop_id }}</el-descriptions-item>
                    <el-descriptions-item label="店铺名">{{ userData.shop_name }}</el-descriptions-item>
                    <el-descriptions-item label="注册时间">{{ userData.createtime }}</el-descriptions-item>
                    <el-descriptions-item label="登录次数">{{ userData.login_num }}</el-descriptions-item>
                    <el-descriptions-item label="最近一次登录">{{ userData.updatetime }}</el-descriptions-item>
                    <el-descriptions-item label="是否试用">
                        <el-tag size="small">{{ userData.is_vip }}</el-tag>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </div>
    </div>
</template>
<style>
.getCursor{
    cursor: pointer;
}
.small-w {
    min-width: 960px;
}
</style>