
<script >
import {getProblem, lineChart, orderCount, orderList} from "@/api/department";
import TT from "@/assets/pages/TT.jpg";
import * as echarts from "echarts";

export default {
    name: 'homePage',
    data() {
        return {
            TT: TT,
            faData: [],
            mainDate: [],
            faDataTotal: 0,
            faDataPage: 1,
            faLimite: 10,
            myCharts: null,
        }
    },
    activated() {
        this.getData()
        this.init()
        this.faDate()
    },
    methods: {
        getData() {
            orderCount().then(res => {
                if (res.code == 1) {
                    const reg = /(&#x)?(.*)/
                    this.mainDate = res.data.map(item => {
                        item.icon = String.fromCharCode(parseInt(reg.exec(item.icon)[2], 16))
                        return item;
                    })
                } else {
                    this.$notify({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
            getProblem().then(res=>{
                console.log(res)
            })
        },
        faDate() {
            const data = {
                page: this.faDataPage,
                limit: this.faLimite
            }
            orderList(data).then(res => {
                if (res.code === 1) {
                    this.faData = res.data.data
                    this.faDataTotal = res.data.total
                } else {
                    this.$notify({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        init() {
            if (this.myCharts) {
                this.myCharts.dispose();//先销毁再创建
            }
            this.myCharts = null;
            echarts.dispose(document.getElementById('mains'))
            this.myCharts = echarts.init(document.getElementById('mains'))
            lineChart().then(res => {
                const option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['采购订单数', '采购金额', '销售金额', '利润']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: res.data.time
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '采购订单数',
                            type: 'line',
                            data: res.data.order_num
                        },
                        {
                            name: '采购金额',
                            type: 'line',
                            data: res.data.dis_money
                        },
                        {
                            name: '销售金额',
                            type: 'line',
                            data: res.data.sale_money
                        },
                        {
                            name: '利润',
                            type: 'line',
                            data: res.data.profit_money
                        },
                    ]
                };
                this.myCharts.setOption(option);
                window.addEventListener('resize', () => {
                    this.myCharts.resize()
                })
            })
        },
        handleSizeChange(val) {
            this.faLimite = val
            this.faDate()
        },
        handleCurrentChange(val) {
            this.faDataPage = val
            this.faDate()
        },
        getJump() {
            this.$router.push({path: '/orderComponent'});
        }

    }
}
</script>
<template>
    <div class="minW getParentPositon">
        <div class="px-8 py-4 bg-white shadow">
            <span class="text-base text-xl">牛牛分销下单</span>
            <div class="flex items-center">
                <span class="text-gray-500 ">目前已支持采购平台:</span>
                <div class="mx-2">
                    <el-button type="success" plain round>1688</el-button>
                    <el-button type="warning" plain round>淘特</el-button>
                </div>

            </div>
            <div class="mx-2 my-2 text-gray-500">
                <p> 1、从1688、淘特（淘宝特价版）厂家一键采购商品，由厂家直接发货给您的买家，并自动更新您当前店铺的订单状态为已发货
                </p>
                <p>
                    2、无需下载安装插件，订单状态自动同步，厂家发货后会自动同步您当前店铺的订单状态。
                </p>
                <p>
                    3、1688、淘特采购下单均使用平台授权的接口进行下单，确保系统稳定可靠。
                </p>
            </div>
        </div>
        <div class="px-8 py-4 withMin bg-white mt-4 shadow">
            <ul class="flex">
                <li :key="item.id" v-for="item in mainDate" @click="getJump"
                    class="cursor-pointer w-1/4 mx-2 border rounded p-3 flex justify-evenly items-start">
                    <div class="flex flex-col justify-center ">
                        <div class="min-w-max text-gray-500">{{ item.name }}</div>
                        <div class="text-2xl">{{ item.total }}</div>
                    </div>
                    <div class="mx-2 text-6xl text-blue-500">
                        {{ item.icon }}
                    </div>
                </li>
            </ul>
        </div>
        <div class="px-4 py-2 bg-white my-4 shadow getWID justify-center">
            <div id="mains"></div>
        </div>
        <div class="px-8 py-4 bg-white  shadow  getSonss">
            <div class="text-xl mb-3 ">
                近期待下单订单
            </div>
                <el-table
                    stripe
                    height="500"
                    :data="faData"
                    style="width: 100%">
                    <template slot="empty">
                        <el-empty ></el-empty>
                    </template>
                    <el-table-column
                        prop="order_id"
                        align="center"
                        label="订单号">
                    </el-table-column>
                    <el-table-column
                        prop="pay_amount"
                        label="订单金额">
                    </el-table-column>
                    <el-table-column
                        label="收件人姓名"
                        prop="order_detailed_one"
                    >
                        <template v-slot:default="scope">
                            <span>{{ scope.row.order_detailed_one.mask_post_receiver }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="pay_time"
                        label="支付时间">
                        <template v-slot:default="scope">
                            <span>{{ new Date(scope.row.pay_time * 1000).toLocaleString() }}</span>
                        </template>
                    </el-table-column>
                </el-table>

            <div class="flex justify-center my-2">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="faDataPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="faDataTotal">
                </el-pagination>
            </div>

        </div>
    </div>
</template>
<style>
.getSonss{
    width: 100%;
    position: absolute;
}
.getParentPositon{
    width: 100%;
    position: relative;
}
.minW {
    min-width: 700px;
    width: 100%;
}

.getWID {
    height: 300px;
}

#mains {
    width: 100%;
    height: 90%;
}
</style>