<script>
import {
    checkAccount,
    getBase,
    getBaseSet,
    getGoods,
    getReason,
    getSelectGoods,
    goodsSyn,
    selectGoods,
} from "@/api/department";

export default {
    name: "SetOrderingParameter",
    data() {
        return {
            essentialnformation: {
                split_order: false,
                le_msg: "",
                order_remarks: "",
            },
            orderPlacement: {
                is_auto: false,
                buy_time: "",
                goods_scope: 1,
            },
            RequestRefund: {
                is_auto_refund: false,
                is_refund_type: "",
                refund_cause: "",
            },
            bye_time: {},
            refund_cause: {},
            RequestRules: {},
            getProductVisible: false,
            chioceList: [],
            alreadList: [],
            productList: [],
            productListTotal: 0,
            product_id: "",
            Limit: 10,
            Page: 1,
        };
    },
    mounted() {
        this.postDate();
        this.getProduct();
    },
    methods: {
        getStatus(){
            checkAccount().then(res=>{
                this.$message.warning(res.msg)
            })
        },
        getProductSub() {
            const product_id = [];
            for (let i = 0; i < this.alreadList.length; i++) {
                product_id.push(this.alreadList[i].product_id);
            }
            const data = {product_id: product_id};
            selectGoods(data).then((res) => {
                if (res.code === 1) {
                    this.getProductVisible = false;
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        postDate() {
            getReason().then((res) => {
                this.bye_time = res.data.bye_time;
                this.refund_cause = res.data.refund_cause;
            });
            getBase().then((res) => {
                const data = res.data;
                if (res.data !== null) {
                    const essentialn = {
                        split_order: data.split_order == 1 ? true : false,
                        le_msg: data.le_msg,
                        order_remarks: data.order_remarks,
                    };
                    this.essentialnformation = essentialn;
                    const orderPlace = {
                        is_auto: data.is_auto == 1 ? true : false,
                        buy_time: Number(data.buy_time),
                        goods_scope: Number(data.goods_scope),
                    };
                    this.orderPlacement = orderPlace;
                    const RequestRefunds = {
                        is_auto_refund: data.is_auto_refund == 1 ? true : false,
                        refund_cause: Number(data.refund_cause),
                        is_refund_type: data.is_refund_type.toString(),
                    };
                    this.RequestRefund = RequestRefunds;
                }
            });
        },
        getProduct(val) {
            const data = {
                type: 0,
                page: this.Page,
                limit: this.Limit,
                name: this.product_id,
            };
            getGoods(data).then((res) => {
                //获取商品列表
                if (res.code == 1) {
                    this.productList = res.data.data;
                    this.productListTotal = res.data.total;
                    if (val) {
                        this.$nextTick(() => {
                            for (let i = 0; i < this.alreadList.length; i++) {
                                const ids = this.productList.find((el) => {
                                    return el.product_id == this.alreadList[i].product_id;
                                });
                                if (ids) {
                                    this.$refs.chioceList.toggleRowSelection(
                                        this.productList.find((el) => {
                                            return el.product_id === this.alreadList[i].product_id;
                                        }),
                                        true
                                    );
                                }
                            }
                        });
                    }
                }
            });
        },
        getProductList() {
            this.alreadList = []
            this.getProductVisible = true;
            getSelectGoods().then((res) => {
                //获取已选商品列表
                this.$nextTick(() => {
                    for (let i = 0; i < res.data.length; i++) {
                        const id = this.alreadList.find(
                            (el) => el.product_id === res.data[i].product_id
                        );
                        if (!id) {
                            this.alreadList.push(res.data[i].goods);
                        }
                        if (res.data[i].goods.product_id !== this.alreadList.product_id) {
                            const ids = this.productList.find((el) => {
                                return el.product_id === res.data[i].goods.product_id;
                            });
                            if (ids) {
                                this.$refs.chioceList.toggleRowSelection(
                                    this.productList.find((el) => {
                                        return el.product_id === res.data[i].goods.product_id;
                                    }),
                                    true
                                );
                            }
                        }
                    }
                });
            });
        },
        handleSizeChange(val) {
            this.Limit = val;
            this.getProduct(1);
        },
        handleCurrentChange(val) {
            this.Page = val;
            this.getProduct(1);
        },
        subPro() {
            this.getProduct(1);
        },
        submitsubmitForm() {
            if (
                this.orderPlacement.buy_time === " " &&
                this.orderPlacement.is_auto === true
            ) {
                this.$message.error("请选择自动购买时间！");
            } else if (
                this.RequestRefund.refund_cause === "" &&
                this.RequestRefund.is_auto_refund === true
            ) {
                this.$message.error("请选择退款原因！");
            } else {
                const data = {
                    split_order: this.essentialnformation.split_order ? 1 : 2,
                    le_msg: this.essentialnformation.le_msg,
                    order_remarks: this.essentialnformation.order_remarks,
                    is_auto: this.orderPlacement.is_auto ? 1 : 2,
                    buy_time: this.orderPlacement.buy_time,
                    goods_scope: this.orderPlacement.goods_scope,
                    is_refund_type: this.RequestRefund.is_refund_type,
                    is_auto_refund: this.RequestRefund.is_auto_refund ? 1 : 2,
                    refund_cause: this.RequestRefund.refund_cause,
                };
                getBaseSet(data).then((res) => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                });
            }
        },
        handleSelection(selection, row) {
            const items = this.alreadList.find((el) => {
                return el.product_id === row.product_id;
            });
            if (items) {
                const index=this.alreadList.findIndex(el=>el.product_id===row.product_id)
                this.alreadList.splice(index, 1);
                this.$refs.chioceList.toggleRowSelection(
                    this.productList.find((el) => {
                        return el.product_id === row.product_id;
                    }),
                    false
                );
            } else {
                this.alreadList.push(row);
                this.$refs.chioceList.toggleRowSelection(
                    this.productList.find((el) => {
                        return el.product_id === row.product_id;
                    }),
                    true
                );
            }
            setTimeout(() => {
                document.getElementById("itemsGET").scrollIntoView({
                    block: "end",
                    behavior: "smooth", // 代表平滑滚动
                });
            });
        },
        syncPro() {
            const data = {
                type: 0,
            };
            goodsSyn(data).then((res) => {
                if (res.code === 1) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            });
            this.getProduct(1);
        },
        delectChioce(index) {
            if (index >= 0) {
                this.$nextTick(() => {
                    this.$refs.chioceList.toggleRowSelection(
                        this.productList.find((el) => {
                            return el.product_id === this.alreadList[index].product_id;
                        }),
                        false
                    );
                    this.alreadList.splice(index, 1);
                });
            } else {
                this.alreadList = [];
                this.$refs.chioceList.clearSelection();
            }
        },
        getAll(selection) {
            if (selection.length === 0) {
                for (let i = 0; i < this.productList.length; i++) {
                    const items = this.alreadList.find((el) => {
                        return el.product_id === this.productList[i].product_id;
                    });
                    if(items){
                        const index=this.alreadList.findIndex(el=>el.product_id===this.productList[i].product_id)
                        this.alreadList.splice(index, 1);
                        this.$nextTick(() => {
                            this.$refs.chioceList.toggleRowSelection(
                                this.productList.find(el => {
                                    return el.product_id === this.productList[i].product_id
                                }), false);
                        })
                    }
                }
            } else {
                for (let i = 0; i < selection.length; i++) {
                    const items = this.alreadList.find((el) => {
                        return el.product_id === selection[i].product_id;
                    });
                    if (!items) {
                        this.alreadList.push(selection[i]);
                        this.$nextTick(() => {
                            this.$refs.chioceList.toggleRowSelection(
                                this.productList.find(el => {
                                    return el.product_id === selection[i].product_id
                                }), true);
                        })
                    }
                }
            }

        },
    },
};
</script>
<template>
    <div class="minW">
        <span class="font-semibold text-gray-600">基本信息</span>
        <div class="bg-white rounded shadow p-8 my-2">
            <el-form
                :model="essentialnformation"
                label-position="right"
                class="demo-form-inline"
            >
                <el-form-item label="拆单发货：">
                    <el-switch v-model="essentialnformation.split_order"></el-switch>
                    <span class="ml-2 align-middle"
                    >(多商品订单采购单发货时，只对对应的卖出子订单进行发货)</span
                    >
                </el-form-item>
                <el-form-item label="采购单订单留言：">
                    <el-input
                        v-model="essentialnformation.le_msg"
                        placeholder="采购单的买家留言，仅1688、淘特厂家可见"
                    ></el-input>
                </el-form-item>
                <el-form-item label="订单备注：">
                    <el-input
                        type="textarea"
                        placeholder="填写订单备注"
                        v-model="essentialnformation.order_remarks"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span class="font-semibold text-gray-600">自动采购下单</span>
        <div class="bg-white rounded shadow p-8 my-2">
            <div class="p-3 rounded wid2 border bg-blue-100 border-blue-700">
                <i class="el-icon-warning"></i>
                以下情况不自动采购下单：
                1、采购单规格不匹配，2、订单已退款
            </div>
            <el-form :model="orderPlacement" class="demo-form-inline">
                <el-form-item label="自动采购下单：" prop="is_auto">
                    <el-switch @change="getStatus" v-model="orderPlacement.is_auto"></el-switch>
                    <span class="ml-2"
                    >(开启后，自动向上家采购商品，如果已开启免密支付会自动完成支付)</span
                    >
                </el-form-item>
                <el-form-item
                    v-if="orderPlacement.is_auto == true"
                    label="发起采购时间："
                    prop="buy_time"
                    :rules="[
            {
              required: true,
              message: '请选择发起采购时间',
              trigger: 'change',
            },
          ]"
                >
                    <el-select v-model="orderPlacement.buy_time" placeholder="选择时间：">
                        <el-option
                            :key="keys"
                            v-for="(item, keys) of bye_time"
                            :label="keys"
                            :value="item"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品范围：" prop="goods_scope">
                    <div>
                        <el-radio-group v-model="orderPlacement.goods_scope">
                            <el-radio :label="1">所有商品</el-radio>
                            <el-radio :label="2">指定商品</el-radio>
                        </el-radio-group>
                        <span class="ml-3" v-if="orderPlacement.goods_scope === 2">
              <el-button type="primary" @click="getProductList"
              >({{
                      alreadList.length == "0" ? "..." : alreadList.length
                  }})选择商品</el-button
              >
            </span>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <span class="font-semibold text-gray-600">自动申请退款</span>
        <div class="bg-white rounded shadow p-8 my-2">
            <div class="p-3 rounded wid2 border bg-blue-100 border-blue-700">
                <i class="el-icon-warning"></i>
                买家申请退款后(仅支持待发货的情况下)，自动向厂家申请退款（会存在10分钟以内的延时）
            </div>
            <el-form
                :rules="RequestRules"
                :model="RequestRefund"
                class="demo-form-inline"
            >
                <el-form-item label="自动申请退款：">
                    <el-switch v-model="RequestRefund.is_auto_refund"></el-switch>
                    <span>(自动向厂家申请退款)</span>
                </el-form-item>
                <el-form-item label="发起申请时间：">
                    <el-radio-group v-model="RequestRefund.is_refund_type">
                        <el-radio label="1">立即发起</el-radio>
                        <el-radio label="2">经商家同意后再发起</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    v-if="RequestRefund.is_auto_refund == true"
                    :rules="[
            { required: true, message: '请选择退款原因', trigger: 'change' },
          ]"
                    label="退款原因："
                    prop="refund_cause"
                >
                    <el-select
                        v-model="RequestRefund.refund_cause"
                        placeholder="选择原因"
                    >
                        <el-option
                            :key="key"
                            v-for="(item, key) of refund_cause"
                            :label="key"
                            :value="item"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="flex flex-col items-center p-3">
            <el-button type="primary" @click="submitsubmitForm">提交</el-button>
        </div>
        <el-dialog title="选择商品" :visible.sync="getProductVisible" width="60%">
            <div class="flex-col justify-start">
                <div class="flex items-center width100 justify-between mb-4">
                    <div>
                        <span style="width: 90px" class="mr-2">商品ID/标题</span>
                        <el-input
                            style="width: 250px"
                            v-model="product_id"
                            placeholder="请输入"
                        ></el-input>
                        <span class="ml-2">
              <el-button type="primary" @click="subPro">查询</el-button>
            </span>
                        <!--                        <span class="ml-2">-->
                        <!--                        <el-button type="primary" @click="subPro">刷新商品</el-button>-->
                        <!--                             </span>-->
                        <span class="ml-2">
              <el-button type="primary" @click="syncPro">同步商品</el-button>
            </span>
                    </div>

                    <div class="float-right flex items-center">
                        <span class="mr-2">已选{{ alreadList.length }}个商品</span>
                        <div
                            @click="delectChioce"
                            class="getCur bg-red-500 text-white px-2 py-1 text-center rounded"
                        >
                            全部清空
                        </div>
                        <!--                        <el-button type="danger" @click="delectChioce" size="mini">全部清空</el-button>-->
                    </div>
                </div>
                <div class="flex justify-evenly">
                    <div class="flex-col w-3/5">
                        <el-table
                            @select-all="getAll"
                            @select="handleSelection"
                            ref="chioceList"
                            height="500"
                            :data="productList"
                            style="width: 100%"
                        >
                            <template slot="empty">
                                <el-empty ></el-empty>
                            </template>
                            <el-table-column align="center" type="selection" width="55">
                            </el-table-column>
                            <el-table-column prop="product_id" label="商品ID" width="180">
                            </el-table-column>
                            <el-table-column prop="name" label="商品名称" width="180">
                            </el-table-column>
                            <el-table-column prop="img" label="商品图片">
                                <template v-slot:default="scope">
                                    <el-image
                                        style="width: 80px; height: 80px"
                                        :src="scope.row.img"
                                    >
                                    </el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="market_price" label="商品价格">
                                <template v-slot:default="scope">
                  <span class="ml-2 text-gray-500"
                  >￥{{ scope.row.market_price }}</span
                  >
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="width100">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="Page"
                                :page-sizes="[10, 20, 30, 40]"
                                :page-size="100"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="productListTotal"
                            >
                            </el-pagination>
                        </div>
                    </div>
                    <div class="border wTableMin hTableMin w-2/5 p-3">
                        <ul id="itemsGET">
                            <li
                                class="flex border-b justify-evenly items-center p-2"
                                :key="item.product_id"
                                v-for="(item, index) in alreadList"
                            >
                                <el-image
                                    style="width: 80px; height: 80px"
                                    :src="item.img"
                                ></el-image>
                                <span class="ml-2 wMax">
                  {{ item.name }}
                </span>
                                <div
                                    @click="delectChioce(index)"
                                    class="getCur text-center bg-red-500 text-white px-1.5 py-1 rounded"
                                >
                                    删除
                                </div>
                                <!--                                <el-button  type="danger" size="mini">-->
                                <!--                                </el-button>-->
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="getProductVisible = false">取 消</el-button>
        <el-button type="primary" @click="getProductSub">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>
<style>
.getCur {
    cursor: pointer;
}

.getCur:hover {
    background-color: #e77c7c;
}

width100 {
    width: 100%;
}

.wMax {
    width: 200px;
}

.hTableMin {
    width: 400px;
}

.wTableMin {
    height: 500px;
    overflow: auto;
}

.minW {
    min-width: 700px;
}

.el-icon-warning {
    color: #1d4ed8;
}

.wid2 {
    width: 90%;
}
</style>