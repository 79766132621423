<script>
import {getProblem} from "@/api/department";

export default {
    name:'GETpROM',
    data(){
        return{
            getCONTENT:''
        }
    },
    created() {
        getProblem().then(res=>{
            this.getCONTENT=res.data.content
            console.log(res)
        })
    },
    methods:{

    }

}
</script>
<template>
    <div class="bg-white rounded shadow ">
        <div class="p-4  flex flex-col items-center">
            <p class="text-3xl">常见问题</p>
            <div class="py-8" v-html="getCONTENT">

            </div>
<!--            有问题请您及时和小助手保持联系哦！<br/>-->
<!--            <a href="https://efpde0crhj.feishu.cn/docx/GiBDdCZZio0uxZxfRhecgY3lnCg" target="_blank">我是新手，查看教程</a>-->
<!--            <a href="/SetOrderingParameter.vue">一键开启【自动发货，自动退款】</a>-->
<!--            1v1指导：19583743949<br/>-->
        </div>

    </div>
</template>